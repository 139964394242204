import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-innovation',
  templateUrl: './about-us-innovation.component.html',
  styleUrls: ['./about-us-innovation.component.scss']
})
export class AboutUsInnovationComponent implements OnInit {

  API_URI = API_URI;
  constructor() { }

  ngOnInit() {
  }

}
