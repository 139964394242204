import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-normativity',
  templateUrl: './normativity.component.html',
  styleUrls: ['./normativity.component.scss']
})
export class NormativityComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  normativity:any = {
    Nombre: 'Normatividad',
    Ubicación: '12',
    Contenido: [
      {
        imageUrl: 'uploads/images/Grupo 1955.png',
        title: 'Normatividad',
        Fields: [
          {
            itemText: 'Ley 1369 de 2009 régimen de servicios postales.',
            itemLink: '#'
          },
          {
            itemText: 'Ley 1408 de 2011 estatuto del consumidor.',
            itemLink: '#'
          }
        ]
      },
      {
        imageUrl: 'uploads/images/Grupo 1974.png',
        title: 'Procedimiento y tramite de PQR',
        Fields: [
          {
            itemText: 'Conoce el procedimiento de tramiite de PQR y solicitud de indemnización para el servicio de giro aquí',
            itemLink: '#'
          }
        ]
      },
      {
        imageUrl: 'uploads/images/Grupo 1951.png',
        title: 'Política de tratamiento de datos',
        description: 'En Efecty protegemos tus datos y hacemos un buen uso de ellos.',
        Fields: [
          {
            itemText: 'Conoce la política de datos aquí.',
            itemLink: 'http://ec2-13-52-138-131.us-west-1.compute.amazonaws.com/uploads/Politica-de-Tratamiento-de-Datos-Personales-1.pdf'
          }
        ]
      },
      {
        imageUrl: 'uploads/images/Grupo 1946.png',
        title: 'Parámetros y niveles de servicio.',
        Fields: [
          {
            itemText: 'Conoce nuestros parámetros y niveles de servicio aquí.',
            itemLink: '#'
          }
        ]
      }
    ]
  }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.normativity = this.contenido;
    }

  }

}
