import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url: any;

  activeModal = true;

  pageTitle: any = {
      Nombre: 'Titulo de Página',
      Ubicacion: '7',
      Contenido: [
      {
        imageTitle: 'uploads/images/page-titles/page-title-giros.png',
        imageAlt: 'uploads/images/page-titles/page-title-giros.png',
        imageUrl: 'uploads/images/page-titles/page-title-giros.png',
        description: 'Envía dinero a cualquier parte del país y lo entregaremos al destinatario a través de nuestra red de más de 9.000 <a href="#">puntos de atención.</a></p>'
      }
    ]
  }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.pageTitle = this.contenido;
    }
  }

}
