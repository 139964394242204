import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { Page } from 'src/app/_models/page';
import { ComponentBase } from 'src/app/_models/component-base';
import { ThrowStmt, ConditionalExpr } from '@angular/compiler';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { Almacen } from 'src/app/_classes/almacen';

@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.scss']
})
export class WebLayoutComponent implements OnInit {

  page: Page ;

  apiUrl = '';

  ComponentBase;

  constructor(
    private route: Router,
    private templateService: TemplateServiceService,
    private activeRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private gtmService: GoogleTagManagerService,
    private almacen: Almacen
  ) {
    this.route.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    this.almacen.loadBase();
    this.loadData();
  }

  async loadData() {
    await this.templateService.getJSON();
    this.apiUrl = this.templateService.getApiUri();
  }

  ngOnInit() {

  }
}
