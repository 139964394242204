import { Validators, ValidatorFn } from '@angular/forms';

export class FieldsBase<T> {
    value: T;
    key: string;
    label: string;
    placeholder: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    styleCms:  boolean;
    hasIcon:  boolean;
    readOnly: boolean;
    icon: string;
    validators: Array<ValidatorFn>
    errormessages: Array<any>
    optionsArray: Array<any>;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        placeholder?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        type?: string,
        styleCms?: boolean,
        hasIcon?:  boolean,
        icon?: string,
        readOnly?: boolean,
        validators?: Array<ValidatorFn>,
        errormessages?: Array<any>,
        optionsArray?: Array<any>,
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.placeholder = options.placeholder || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.styleCms = !!options.styleCms;
      this.hasIcon= !!options.hasIcon;
      this.icon= options.icon || '';
      this.readOnly= !!options.readOnly;
      this.validators = options.validators?options.validators:[];
      this.errormessages = options.errormessages?options.errormessages:[];
      this.optionsArray = options.optionsArray || [];
    }
  }
