import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';
import { Colombia } from 'src/app/_classes/colombia';

@Component({
  selector: 'app-form-contact-advisory',
  templateUrl: './form-contact-advisory.component.html',
  styleUrls: ['./form-contact-advisory.component.scss'],
  providers: [ComponentService]
})
export class FormContactAdvisoryComponent implements OnInit {

  statusPhone: boolean = true;
  statusMobile: boolean = true;
  noSubmit: boolean = false;

  formContactAdvisory = {
    Nombre: 'Formulario para contactar un asesor',
    Ubicacion: '24',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          title: 'Si cumples con los requisitos, diligencia el formulario para contactar al asesor:',
          subtitle: 'Ten a la mano una fotocopia de la cédula, RUT y/ o cámara de comercio',
          description: 'Todos los campos son obligatorios'
        }
      },
      {
        Propiedad: 'form',
        Contenido: {
          action: 'a',
          field: '¿Tienes un local comercial?',
          fieldOptionOne: 'Si',
          fieldOptionTwo: 'No',
          fieldCual: 'Cual?',
          placeholderCual:  'Escribe que tipo de negocio tienes',
          fieldName: 'Nombres y apellidos:',
          placeholderName:  'Escribe tu nombre completo',
          fieldDocument: 'Tipo de documento:',
          fieldDocumentOptionOne: 'CC',
          fieldDocumentOptionTwo: 'CE',
          fieldDocumentOptionThree: 'NIT',
          fieldDocumentNumber: 'Número de documento',
          placeholderDocumentNumber: 'Escribe tu número sin puntos o comas',
          fieldEmail: 'Correo electrónico:',
          placeholderEmail: 'Escribe tu correo personal o del negocio',
          fieldPhone: 'Teléfono fijo (opcional):',
          placeholderPhone: 'Escribe los 7 digitos de tu teléfono',
          fieldMobile: 'Teléfono celular:',
          placeholderMobile: 'Escribe los 10 digitos de tu teléfono',
          fieldAddPhone: 'Agregar otro teléfono',
          fieldRemovePhone: 'Quitar teléfono',
          fieldDepartment: 'Departamento:',
          placeholderDepartment: 'Escribe el departamento',
          fieldMunicipality: 'Municipio:',
          placeholderMunicipality: 'Escribe el municipio',
          fieldCity: 'Ciudad o localidad:',
          placeholderCity: 'Escribe la ciudad o localidad',
          terms: '<span>Acepto</span> <a href="http://ec2-13-52-138-131.us-west-1.compute.amazonaws.com/uploads/Politica-de-Tratamiento-de-Datos-Personales-1.pdf" target="_blank">Términos y condiciones.</a>',
          policy: 'Conoce nuestra <a href="http://ec2-13-52-138-131.us-west-1.compute.amazonaws.com/uploads/Politica-de-Tratamiento-de-Datos-Personales-1.pdf" target="_blank">política de tratamiento de datos</a>',
          button: 'Contactar un asesor'
        }
      }
    ]
  };

  listaTiposNegocio: any = [
    'Agencias de Diseño y Publicidad',
    'Almacén de Cadena / Electrodomésticos',
    'Almacenes de Calzado y Ropa',
    'Asesorias y Tramites',
    'Café Internet / Cabinas Telefónicas',
    'Cajeros Electronicos',
    'Casa de Cambio',
    'Centro de Soluciones Servientrega',
    'Compañías de Transporte',
    'Cooperativa Solidarias y Microcredito',
    'Corresponsal Bancario',
    'Dealer Telecomunicaciones',
    'Distribuidores De Gas ',
    'Droguería',
    'Estación de Servicio (EDS)',
    'Estudio Fotografico',
    'Exclusivo',
    'Ferretería',
    'Hoteleria y Turismo',
    'Minimercado / Tienda de Conveniencia',
    'Papelería / Miscelánea',
    'Parqueaderos',
    'Productos Agricolas',
    'Productos de Aseo',
    'Productos De Belleza',
    'Productos Tecnológicos y Soporte',
    'Salud y Bienestar ',
    'Servicio de Mensajeria',
    'Servitecas y Concesionarios',
    'Supermercado / Gran Superficie',
    'Tienda De Barrio',
    'Otros',
  ];

  departamentos = Colombia;
  municipios: any = [];
  ciudades: any = [];
  pageForm: FormGroup;


  modal = {
    image : 'assets/images/title-modal-send.png',
    title : 'assets/images/icon-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, text){
      this.image = image;
      this.title = title;
      this.text = text;
      this.active = true;
    }
  }

  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image){
      this.image = image;
      this.active = true;
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService
  ) { }

  ngOnInit() {
    this.pageForm = this.formBuilder.group({
      localcomercial : ['', Validators.required],
      tipo_negocio : ['', Validators.required],
      nombre : ['',
        [Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.minLength(1),
        Validators.maxLength(250)]
      ],
      tipodocumento : ['', Validators.required],
      numerodocumento : ['', [Validators.required,Validators.max(999999999999999)]],
      correo : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(50),Validators.email]],
      telefono : ['', [Validators.minLength(1),Validators.max(999999999999999)]],
      telefono2 : ['', [Validators.minLength(1),Validators.max(9999999999999)]],
      mobile : ['', [Validators.required,Validators.minLength(1),Validators.max(999999999999999)]],
      mobile2 : ['', [Validators.minLength(1),Validators.max(999999999999999)]],
      departamento : ['', Validators.required],
      municipio : ['', Validators.required],
      ciudad : [''],
      cual: [''],
      terminos: [null, [Validators.required, Validators.requiredTrue]]
    });
  }

  opcionSeleccionado: string  = ''; // Iniciamos
  verSeleccion: string        = '';

  capturar($event) {
    this.verSeleccion = this.opcionSeleccionado;
  }

  changeCity(evento){
    const departamento = this.departamentos.find(item => item.departamento == this.pageForm.value.departamento);
    this.municipios = departamento.ciudades;
  }

  changeMunicipio(id){
    this.ciudades = this.municipios;
  }

  submit(){
    this.loading.show( 'assets/images/loading.gif');
    if (this.pageForm.invalid) {
      this.modal.show(
        'assets/images/icon-modal-error.png',
        'assets/images/title-modal-error.png',
        'Todos los cambios del formulario son requeridos'
        );
        this.loading.hidde();
      return;
    }
    this.apiService.postQuiero(this.pageForm.value)
      .subscribe(
        response => {
          this.pageForm.reset();
          this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Recibimos tus datos');
          this.loading.hidde();
        },
        err =>{
          this.modal.show('assets/images/icon-modal-error.png',  'assets/images/title-modal-error.png', 'Intenta de nuevo mas tarde');
          this.loading.hidde();
        }
        );
  }

  enableSubmit(){
    this.noSubmit = false;
  }

  onClickMe() {
    this.noSubmit = true;
    this.modal.show(
      'assets/images/modal-error-negocio-propio.png',
      '',
      'Gracias por contactarnos. ' +
      'Debido a políticas de efecty, te informamos que no es viable la vinculación '+
      'puesto que es necesario contar con establecimiento comercial propio.'
    );
  }

  togglePhone() {
    this.statusPhone = !this.statusPhone;
  }

  toggleMobile() {
    this.statusMobile = !this.statusMobile;
  }

  toogleSubmit(){
    this.noSubmit = !this.noSubmit;
  }
}

