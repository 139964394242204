
import{Component, ContentChildren, QueryList, AfterContentInit, ViewChild, ComponentFactoryResolver, ViewContainerRef,  Output, EventEmitter} from '@angular/core';
import { TabComponent } from './tab.component';

  @Component({
    selector: 'my-tabs',
    template: `
      <ul class="nav nav-tabs flex-xs evenly-xs body-tab">
        <li *ngFor="let tab of tabs;" (click)="selectTab(tab)" class="item-tab"  [class.active]="tab.active">
            <a class="pd-bottom-15">{{tab.title}}</a>
            <div class="botn center-xs flex-xs"></div>
        </li>
      </ul>
      <ng-content></ng-content>
    `,
    styleUrls: ['./tabs.component.scss']
  })
  export class TabsComponent implements AfterContentInit {

    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
    /**
     * Evento de envio de formulario
     *
     * @property {EventEmitter<boolean>}
     */
    @Output() enviarFormulario = new EventEmitter<boolean>();

    ngAfterContentInit() {
      // get all active tabs
      let activeTabs = this.tabs.filter((tab)=>tab.active);

      // if there is no active tab set, activate the first
      if(activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }

    selectTab(tab){
      this.tabs.toArray().forEach(tab => tab.active = false);
      tab.active = true;
      this.enviarFormulario.emit(true);
    }
  }
