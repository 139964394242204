import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-image-title',
  templateUrl: './image-title.component.html',
  styleUrls: ['./image-title.component.scss']
})
export class ImageTitleComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;


  imageTitle:any =
    {
      Nombre: 'Imagen de titulo',
      Ubicacion: '4',
      Contenido: [
        {
          imageUrl: 'uploads/images/atencion-al-cliente-titulo.png',
          imageAlt: 'Promociones y Campañas',
          imageTitle: 'Promociones y Campañas',
          title: 'Promociones y Campañas',
        }
      ]
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.imageTitle = this.contenido;
    }

  }

}
