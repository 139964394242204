import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-information-gyre',
  templateUrl: './form-information-gyre.component.html',
  styleUrls: ['./form-information-gyre.component.scss']
})
export class FormInformationGyreComponent implements OnInit {

  formInformationGyre = {
    Nombre: 'Formulario para contactar un asesor',
    Ubicacion: '26',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          title: 'Información de tu giro'
        }
      },
      {
        Propiedad: 'form',
        action: '/',
        Contenido: [
          {
            type: 'text',
            label: 'Número de Giro (Prueba de Admisión):'
          },
          {
            type: 'date',
            label: 'Fecha de creación:'
          },
          {
            type: 'text',
            label: 'Estado:'
          },
          {
            type: 'text',
            label: 'Ps Destino:'
          },
          {
            type: 'text',
            label: 'Dirección PS:'
          },
          {
            type: 'text',
            label: 'Municipio:'
          }
        ],
        buttonOne: 'Imprimir prueba de admisión',
        buttonTwo: 'Imprimir prueba de entrega',
        buttonThree: 'Regresar'
      }
    ]
  }

  constructor() { }

  ngOnInit() {
  }

}
