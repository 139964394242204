import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

  @Input() title = 'Titulo';
  @Input() image = 'assets/images/Grupo-1892.png';
  @Input() text = 'Aqui va el mensaje ... ';
  @Input() display = false;

  constructor() { }

  ngOnInit() {
  }

  hidde() {
    this.display = false;
  };
  show(title, message){
    this.title = title;
    this.text = message;
    this.display = true;
  }

}
