import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-attention-specialized',
  templateUrl: './attention-specialized.component.html',
  styleUrls: ['./attention-specialized.component.scss']
})
export class AttentionSpecializedComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url: any;
  private data: any = {};

  attentionSpecialized:any =
    {
      Nombre: 'Atención especializada',
      Ubicación: '9',
      Contenido: [
      {
        imageUrlMovil:null,
        imageUrlDesktop:null,
        imageAlt:null,
        imageTitle:null,
        background: 'bg-graylight',
        title: 'Resuelve tus dudas y recibe una atención especializada',
        Fields: [
          {
            title: 'Preguntas frecuentes',
            description: 'Respuestas a las preguntas más comunes',
            imageUrl:'/preguntas-frecuentes',
            imageAlt:'/preguntas-frecuentes',
            imageTitle:'/preguntas-frecuentes',
            linkUrl:'/preguntas-frecuentes',
            textPosition: 'in',
          },
          {
            linkUrl: '/radica-peticioones-quejas-o-sugerencias',
            imageUrl: 'uploads/images/Grupo 1974.png',
            title: 'Radica Peticiones quejas o sugerencias',
            description: 'Si tienes una petición, queja o sugerencia, ingresa y completa el formulario',
            textPosition: 'out'
          },
          {
            linkUrl: '/consulta-tu-pqrs',
            imageUrl: 'uploads/images/Grupo 2017.png',
            title: 'Consulta tu PQRS',
            textPosition: 'in',
          }
        ]
      }
    ]
  }

  constructor() {

   }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.attentionSpecialized = this.contenido;
    }
  }

}
