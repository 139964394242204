import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-international-conditions-charge',
  templateUrl: './international-conditions-charge.component.html',
  styleUrls: ['./international-conditions-charge.component.scss']
})
export class InternationalConditionsChargeComponent implements OnInit {

  internationalConditionsCharge =
    {
      Nombre: 'Giros internacionales condiciones para cobrar',
      Ubicacion: '14',
      Contenido: [
        {
          Propiedad: 'image',
          Contenido: {
            image: API_URI+'uploads/images/condiciones-para-el-cobro-de-un-giro.png',
            alt: 'Condiciones para el cobro de un giro internacional en los puntos de atención'
          }
        },
        {
          Propiedad: 'list',
          Contenido: [
            {
              Propiedad: 'header',
              Contenido: {
                title: 'Condiciones para el cobro de un giro internacional en los puntos de atención',
                description: "Debes presentar la Cédula de Ciudadanía y suministrar la siguiente información al cajero del punto de atención:"
              }
            },
            {
              Propiedad: 'items',
              Contenido: [
                {
                  item: 'MTCN (Money Transfer Control Number) ó código de transferencia.'
                },
                {
                  item: 'Nombres y Apellidos del remitente del giro internacional.'
                },
                {
                  item: 'País o ciudad origen de donde le envían el giro internacional.'
                },
                {
                  item: 'Valor del giro internacional.'
                }
              ]
            }
          ]
        }
      ]
    }

  constructor() { }

  ngOnInit() {
  }

}
