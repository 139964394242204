import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-steps-gallery',
  templateUrl: './steps-gallery.component.html',
  styleUrls: ['./steps-gallery.component.scss']
})
export class StepsGalleryComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  private data: any = {};

  stepsGallery: Array<any> = [
    {
      titleImageMobile: 'uploads/images/giros-por-internet-titulo-imagen.png',
      titleImageDesktop: 'uploads/images/como-realizar-un-giro-por-internet.png',
      titleImageAlt: 'Giros por internet',
      titleImageTitle: 'Giros por internet',
      Fields: [
        {
          number: '1',
          icon: 'uploads/images/Grupo -1.png',
          title: 'Debes tener una<br> cuenta bancaria',
          description: 'Debes tener una cuenta bancaria habilitada para pagos en internet.'
        },
        {
          number: '2',
          icon: 'uploads/images/Grupo -2.png',
          title: 'Ingresa los datos <br>del giro',
          description: '<a href="#">Inicia sesión o regístrate</a> en nuestra plataforma e ingresa los datos del giro.'
        },
        {
          number: '3',
          icon: 'uploads/images/Grupo -3.png',
          title: 'Validaremos tu<br> identidad',
          description: 'Validaremos tu identidad con una foto tomada con tu cámara web.'
        },
        {
          number: '4',
          icon: 'uploads/images/Grupo -4.png',
          title: 'Realiza tu pago<br> y envía el giro',
          description: 'Realiza tu pago por PSE y se enviará tu giro. ¡Es así de fácil!'
        }
      ],
      buttonText: 'Realiza un giro',
      buttonLink: '#',
      contract: "* Conoce nuestro <a href='#'>contrato de prestación de servicio.</a>"
    }
  ];

  constructor() { }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.stepsGallery = this.contenido.Contenido;
    }
  }
}
