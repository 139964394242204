import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informative-content-type-two',
  templateUrl: './informative-content-type-two.component.html',
  styleUrls: ['./informative-content-type-two.component.scss']
})
export class InformativeContentTypeTwoComponent implements OnInit {

  informativeContentTypeTwo = {
    Nombre: 'Estatico 2',
    Ubicación: '15',
    Contenido: [
      {
        Propiedad: 'cols',
        Contenido: [
          {
            Propiedad: 'col1',
            icon: API_URI+'uploads/images/Grupo-65@2x.png',
            title: '<h3>En puntos <br>de atención</h3>',
            description: 'Tus proveedores podrán hacer su pago a través de los 9.000 puntos de atención.',
            Contenido: [
              {
                item: 'Más de 1.000 convenios de pago.'
              },
              {
                item: 'Integración con sistema interno.'
              },
              {
                item: 'Consulta de bases de datos a clientes.'
              }
            ]
          },
          {
            Propiedad: 'col1',
            icon: API_URI+'uploads/images/steps/Grupo-67@2x.png',
            title: '<h3>En línea</h3>',
            description: 'Tenemos un botón de pagos especializado:',
            Contenido: [
              {
                item: 'Protejemos la información bancaria de tus clientes.'
              },
              {
                item: 'Notificamos en línea cuando te realicen un pago'
              },
              {
                item: 'Ejemplo beneficio.'
              }
            ]
          }
        ]
      },
      {
        Propiedad: 'button',
        Contenido: {
          buttonText: 'Contacta un asesor',
          buttonLink: '/contacto'
        }
      }
    ]
  }

  constructor() { }

  ngOnInit() {
  }

}
