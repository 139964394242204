import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ViewChild, Input  } from '@angular/core';
import { TabsComponent } from './tabscomponent/tabs.component';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ComponentBase } from 'src/app/_models/component-base';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-tabs-sliders',
  templateUrl: './tabs-sliders.component.html',
  styleUrls: ['./tabs-sliders.component.scss']
})
export class TabsSlidersComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  tabsSliders: any  = {
    Nombre: '',
    Ubicacion: '',
    title: '¿Qué puedo pagar?',
    background: 'white',
    Contenido: [
      {
        title: 'Telefomunicaciones',
        content: 'Lorem ipsum dolor sit amet, utroque splendide an quo. Omnesque pertinacia efficiantur vix at, soleat quaeque assueverit et vis. Te sit tale eripuit corrumpit, cum ea case graeci legimus. Sea ex assentior honestatis adversarium. Mei ea dico meis instructior, no eum ipsum voluptatum, quodsi pertinax postulant in sed. Te eum pertinacia suscipiantur, sea eirmod sanctus ea. Vel habeo feugait ea, an apeirian adversarium nam.',
        Fields: [
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          }
        ],
        button: {
          link: 'link',
          title: 'boton'
        }
      },
      {
        title: 'Servicios públicos',
        content: 'Lorem ipsum dolor sit amet, utroque splendide an quo. Omnesque pertinacia efficiantur vix at, soleat quaeque assueverit et vis. Te sit tale eripuit corrumpit, cum ea case graeci legimus. Sea ex assentior honestatis adversarium. Mei ea dico meis instructior, no eum ipsum voluptatum, quodsi pertinax postulant in sed. Te eum pertinacia suscipiantur, sea eirmod sanctus ea. Vel habeo feugait ea, an apeirian adversarium nam.',
        Fields: [
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          }
        ],
        button: {
          link: 'link',
          title: 'boton'
        }
      },
      {
        title: 'Microcrédito',
        content: 'Lorem ipsum dolor sit amet, utroque splendide an quo. Omnesque pertinacia efficiantur vix at, soleat quaeque assueverit et vis. Te sit tale eripuit corrumpit, cum ea case graeci legimus. Sea ex assentior honestatis adversarium. Mei ea dico meis instructior, no eum ipsum voluptatum, quodsi pertinax postulant in sed. Te eum pertinacia suscipiantur, sea eirmod sanctus ea. Vel habeo feugait ea, an apeirian adversarium nam.',
        Fields: [
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          }
        ],
        button: {
          link: 'link',
          title: 'boton'
        }
      },
      {
        title: 'Venta directa',
        content: 'Lorem ipsum dolor sit amet, utroque splendide an quo. Omnesque pertinacia efficiantur vix at, soleat quaeque assueverit et vis. Te sit tale eripuit corrumpit, cum ea case graeci legimus. Sea ex assentior honestatis adversarium. Mei ea dico meis instructior, no eum ipsum voluptatum, quodsi pertinax postulant in sed. Te eum pertinacia suscipiantur, sea eirmod sanctus ea. Vel habeo feugait ea, an apeirian adversarium nam.',
        Fields: [
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'a'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          },
          {
            imageUrl: './assets/images/Grupo 2074.png',
            imageAlt: './assets/images/Grupo 2074.png',
            imageTitle: './assets/images/Grupo 2074.png',
            link: 'b'
          }
        ],
        button: {
          link: 'link',
          title: 'boton'
        }
      }
    ]
  };

  slideConfig: any  = {
    "slidesToShow": 6,
    'slidesToScroll': 6,
    "autoplay": true,
    "responsive": [
      {
        'breakpoint': 960,
        'settings': {
          "slidesToShow": 2,
          'slidesToScroll': 2
        }
      },
      {
        'breakpoint': 720,
        'settings': {
          "slidesToShow": 1,
          'slidesToScroll': 1
        }
      }
    ],
    "infinite": false
  };

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.tabsSliders = this.contenido;
    }
  }

  clickTab(e){
    setTimeout(function(){
      $('.slick-slider').slick('setPosition')
    },50);
  }

  ngAfterContentInit(){
    setTimeout(function(){
      $('.slick-slider').slick('setPosition')
    },50);
  }

}
