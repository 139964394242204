export class TemplateBase<T> {
  Nombre: string;
  Ubicacion: string;
  Contenido: Array<object>;

  constructor(options: {
    Nombre?: string;
    Ubicacion?: string;
    Contenido?: Array<object>
  } = {}) {
    this.Nombre = options.Nombre || '';
    this.Ubicacion = options.Ubicacion || '';
    this.Contenido = !!options.Contenido ? TemplateBase.getObject(options.Contenido) : [];
  }

  public static getProperties(instance) {
    let newArray = new Array, Content;
    Content = Object.getOwnPropertyNames(instance);
    for (let i = 0; i < Content.length; i++)
      newArray.push({ Propiedad: Content[i], Contenido: instance[Content[i]] })
    return newArray
  }

  public static getObject(data): any {
    let newObject = new Object
    data.forEach(element => newObject[element.Propiedad] = element.Contenido)
    return newObject
  }
}
