import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-regional-contact-numbers',
  templateUrl: './regional-contact-numbers.component.html',
  styleUrls: ['./regional-contact-numbers.component.scss']
})
export class RegionalContactNumbersComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  regionalContactNumbers:any =
    {
      Nombre: 'Teléfonos de Contacto por Regional',
      Ubicacion: '5',
      Contenido: [
        {
          tableBorderColor: 'red',
          title: 'Regional Bogotá',
          Fields: [
                {
                  region: 'Regional Bogotá',
                  number: 'Cel: 3057341335'
                },
                {
                  region: 'Bogotá',
                  number: '(1) 7427221'
                }
              ]
        },
        {
          title: 'Regional Antioquia',
          Fields: [
            {
              region: 'Regional Antioquia',
              number: 'Cel: 3057341334'
            },
            {
              region: 'Medellín',
              number: '(4) 6051813'
            }
          ]
        },
            {
              title: 'Regional Centro',
              Fields: [
                {
                  region: 'Regional Centro',
                  number: 'Cel: 3057341336'
                },
                {
                  region: 'Tenjo',
                  number: '(1) 7427223'
                },
                {
                  region: 'Sogamoso',
                  number: '(8) 7751823'
                },
                {
                  region: 'Flandes',
                  number: '(8) 2469350'
                },
                {
                  region: 'Girardot',
                  number: '(1) 8886084'
                },
                {
                  region: 'Yopal',
                  number: '(8) 6336357'
                },
                {
                  region: 'Tunja',
                  number: '(8) 7471074'
                },
                {
                  region: 'Facatativa',
                  number: '(1) 8903962'
                },
                {
                  region: 'Villavicencio',
                  number: '(8) 6740860'
                },
                {
                  region: 'Duitama',
                  number: '(8) 7651806'
                }
              ]
            },
            {
              title: 'Regional Caribe',
              Fields: [
                {
                  region: 'Regional Caribe',
                  number: 'Cel: 3057341337'
                },
                {
                  region: 'Montería',
                  number: '(4) 7890547'
                },
                {
                  region: 'Sincelejo',
                  number: '(5) 2771138'
                },
                {
                  region: 'Cartagena',
                  number: '(5) 6932732'
                }
              ]
            },
            {
              title: 'Regional Eje Cafetero',
              Fields: [
                {
                  region: 'Regional Eje Cafetero',
                  number: 'Cel: 3057341338'
                },
                {
                  region: 'Pereira',
                  number: '(6) 3402411'
                },
                {
                  region: 'Armenia',
                  number: '(6) 7358265'
                },
                {
                  region: 'Manizales',
                  number: '(6) 8962200'
                },
                {
                  region: 'Ibague',
                  number: '(8) 2770763'
                }
              ]
            },
            {
              title: 'Regional Oriente',
              Fields: [
                {
                  region: 'Regional Oriente',
                  number: 'Cel: 3057341339'
                },
                {
                  region: 'Bucaramanga',
                  number: '(7) 6973419'
                },
                {
                  region: 'Cúcuta',
                  number: '(7) 5956411'
                },
                {
                  region: 'Lebrija',
                  number: '(7) 6663167'
                }
              ]
            },
            {
              title: 'Regional Norte',
              Fields: [
                {
                  region: 'Regional Norte',
                  number: 'Cel: 3057341341'
                },
                {
                  region: 'Barranquilla',
                  number: '(5) 3851636'
                },
                {
                  region: 'Santa Marta',
                  number: '(5) 4351934'
                },
                {
                  region: 'Valledupar',
                  number: '(5) 5885543'
                }
              ]
            },
            {
              title: 'Regional Occidente',
              Fields: [
                {
                  region: 'Regional Occidente',
                  number: 'Cel: 3057341340'
                },
                {
                  region: 'Cali',
                  number: '(2) 4856543'
                },
                {
                  region: 'Buenaventura',
                  number: '(2) 2978339'
                },
                {
                  region: 'Popayán',
                  number: '(2) 8339265'
                },
                {
                  region: 'Pasto',
                  number: '(2) 7382201'
                }
              ]
            },
            {
              title: 'Regional Sur',
              Fields: [
                {
                  region: 'Regional Sur',
                  number: 'Cel: 3057341342'
                },
                {
                  region: 'Neiva',
                  number: '(8) 8631063'
                },
                {
                  region: 'Aipe',
                  number: '(8) 8398380'
                },
                {
                  region: 'Rivera',
                  number: '(8) 8388272'
                }
              ]
            }
          ]
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.regionalContactNumbers = this.contenido;
    }
  }

}
