import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-international-conditions-send',
  templateUrl: './international-conditions-send.component.html',
  styleUrls: ['./international-conditions-send.component.scss']
})
export class InternationalConditionsSendComponent implements OnInit {
  API_URI = API_URI;
  constructor() { }

  ngOnInit() {
  }

}
