import { Component, OnInit } from '@angular/core';
import { ComponentService } from '../../_services/templates.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { Colombia } from 'src/app/_classes/colombia';

@Component({
  selector: 'app-form-advisory-type-two',
  templateUrl: './form-advisory-type-two.component.html',
  styleUrls: ['./form-advisory-type-two.component.scss']
})
export class FormAdvisoryTypeTwoComponent implements OnInit {

  formAdvisoryTypeTwo = {
    Nombre: 'Formulario para asesoría largo',
    Ubicacion: '25',
    Contenido: {
      action: 'form',
      Contenido: [
        {
          Propiedad: 'number',
          type: 'number',
          label: '¿Cuantos establecimientos de comercio abiertos al público tienes?*',
          control: 'cantidad',
          placeholder: 'Escribe solo números'

        },
        {
          Propiedad: 'selecttipo',
          label: 'Tipo de establecimiento*',
          control: 'tipo',
          Contenido: [
            {
              option: 'Cadena de supermercados'
            },
            {
              option: 'Agencias de Diseño y Publicidad',
            },
            {
              option:'Almacén de Cadena / Electrodomésticos',
            },
            {
              option:'Almacenes de Calzado y Ropa',
            },
            {
              option:'Asesorias y Tramites',
            },
            {
              option:'Café Internet / Cabinas Telefónicas',
            },
            {
              option:'Cajeros Electronicos',
            },
            {
              option:'Casa de Cambio',
            },
            {
              option:'Centro de Soluciones Servientrega',
            },
            {
              option:'Compañías de Transporte',
            },
            {
              option:'Cooperativa Solidarias y Microcredito',
            },
            {
              option:'Corresponsal Bancario',
            },
            {
              option:'Dealer Telecomunicaciones',
            },
            {
              option:'Distribuidores De Gas ',
            },
            {
              option:'Droguería',
            },
            {
              option:'Estación de Servicio (EDS)',
            },
            {
              option:'Estudio Fotografico',
            },
            {
              option:'Exclusivo',
            },
            {
              option:'Ferretería',
            },
            {
              option:'Hoteleria y Turismo',
            },
            {
              option:'Minimercado / Tienda de Conveniencia',
            },
            {
              option:'Papelería / Miscelánea',
            },
            {
              option:'Parqueaderos',
            },
            {
              option:'Productos Agricolas',
            },
            {
              option:'Productos de Aseo',
            },
            {
              option:'Productos De Belleza',
            },
            {
              option:'Productos Tecnológicos y Soporte',
            },
            {
              option:'Salud y Bienestar ',
            },
            {
              option:'Servicio de Mensajeria',
            },
            {
              option:'Servitecas y Concesionarios',
            },
            {
              option:'Supermercado / Gran Superficie',
            },
            {
              option:'Tienda De Barrio',
            },
            {
              option:'Otros',
            },
          ]
        },
        {
          Propiedad: 'inputcual',
          type: 'text',
          label: 'Cual?*',
          placeholder: 'Escribe que tipo de negocio tienes',
          control: 'tipo'
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Razón social*',
          control: 'razonsocial',
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Nombre comercial de tu empresa*',
          control: 'nombre'
        },
        {
          Propiedad: 'select',
          label: 'Tipo de documento*',
          control: 'tipodocumento',
          Contenido: [
            {
              option: 'Cédula de Ciudanía',
              value: 'cc'
            },
            {
              option: 'Cédula de Extranjería',
              value: 'ce'
            },
            {
              option: 'Pasaporte',
              value: 'pp'
            },
            {
              option: 'NIT',
              value: 'nit'
            }
          ]
        },
        {
          Propiedad: 'number',
          type: 'number',
          label: 'Número de documento*',
          placeholder: 'Sin puntos ni comas',
          control: 'numerodocumento'
        },
        {
          Propiedad: 'number',
          type: 'number',
          label: 'Dígito de verificación*',
          placeholder: 'Escribe tu dirección completa',
          control: 'digitoverficacion',
        },
        {
          Propiedad: 'selectD',
          label: 'Departamento*',
          control: 'departamento',
          Contenido: [
            {
              option: 'Seleccione una Opción'
            }
          ]
        },
        {
          Propiedad: 'selectMunicipio',
          label: 'Municipio*',
          control: 'municipio',
          Contenido: [
            {
              option: 'Municipio'
            },
            {
              option: 'Otro'
            }
          ]
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Dirección*',
          control: 'direccion',
        },
        {
          Propiedad: 'number',
          type: 'number',
          label: 'Número de teléfono*',
          control: 'telefono',
          placeholder: 'Sin puntos ni comas'
        },
        {
          Propiedad: 'title',
          title: 'Datos de contacto',
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Nombre de contacto*',
          control: 'contactonombre',
          pattern: ''
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Apellido del contacto*',
          control: 'contactoapellido',
          pattern: ''
        },
        {
          Propiedad: 'input',
          type: 'text',
          label: 'Cargo del contacto*',
          control: 'contactocargo'
        },
        {
          Propiedad: 'number',
          type: 'number',
          label: 'Número de celular*',
          placeholder: 'Sin puntos ni comas',
          control: 'contactotelefono'
        },
        {
          Propiedad: 'input',
          type: 'email',
          label: 'Correo electrónico*',
          placeholder: 'Escribe tu correo personal o del negocio',
          control: 'contactocorreo'
        },
        {
          Propiedad: 'select',
          label: 'Tipo de documento:*',
          control: 'contactotipodocumento',
          Contenido: [
            {
              option: 'Cédula de Ciudanía',
              value: 'cc'
            },
            {
              option: 'Cédula de Extranjería',
              value: 'ce'
            },
            {
              option: 'Pasaporte',
              value: 'pp'
            }
          ]
        },
        {
          Propiedad: 'number',
          type: 'number',
          label: 'Número de documento*',
          placeholder: 'Sin puntos ni comas',
          control: 'contactonumerodocumento',
        },
        {
          Propiedad: 'textarea',
          label: 'Mensaje*',
          control: 'mensaje',
          value: ''
        },
      ],
      terms: 'Autorizo tratamiento de datos personales',
      policyText: 'Conoce la política de datos aquí',
      policyLink: 'http://ec2-13-52-138-131.us-west-1.compute.amazonaws.com/uploads/Politica-de-Tratamiento-de-Datos-Personales-1.pdf',
      button: 'Enviar mis datos'
    }
  };
  pageForm: FormGroup;

  opcionSeleccionado: string  = ''; // Iniciamos
  verSeleccion: string        = '';

  capturar() {
    this.verSeleccion = this.opcionSeleccionado;
  }


  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }

  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image){
      this.image = image;
      this.active = true;
    }
  };

  departamentos = Colombia;
  municipios: any = [];
  ciudades: any = [];

  changeCity(evento){
    const departamento = this.departamentos.find(item => item.departamento == this.pageForm.value.departamento);
    this.municipios = departamento.ciudades;
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService
  ) { }

  ngOnInit() {
    this.pageForm = this.formBuilder.group({
      cantidad : ['',Validators.required],
      tipo : ['',Validators.required],
      razonsocial : ['',Validators.required],
      nombre :  ['',
        [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250)]
      ],
      tipodocumento : ['', [Validators.required]],
      numerodocumento : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(15)]],
      digitoverficacion : ['', Validators.required],
      direccion : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(15)]],
      municipio : ['', Validators.required],
      telefono : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(15)]],
      contactonombre : ['',
        [Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.minLength(1),
        Validators.maxLength(250)]
      ],
      contactoapellido : ['',
        [Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.minLength(1),
        Validators.maxLength(250)]
      ],
      contactocargo : ['', [Validators.required]],
      contactotelefono : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(15)]],
      contactocorreo : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(50),Validators.email]],
      contactotipodocumento : ['', Validators.required],
      contactonumerodocumento : ['', [Validators.required,Validators.minLength(1),Validators.maxLength(15)]],
      mensaje : ['', Validators.required],
      ciudades: [''],
      departamento : ['', Validators.required],
      inputcual: [''],
      terms: ['', [Validators.required, Validators.requiredTrue]],
    });

  }

  submit() {
    this.loading.show( 'assets/images/loading.gif');
    if(this.pageForm.invalid){
      this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Campos invalidos');
      this.loading.hidde();
      return false;
    }
    this.apiService.postAsesoria(this.pageForm.value)
      .subscribe(
        response => {
          this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Recibimos la informacion');
          this.loading.hidde();
          this.pageForm.reset();
        },
        err => {
          this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err.message);
          this.loading.hidde();
        }
      )
  }

}
