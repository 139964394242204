import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-work-with-us-benefits-and-training',
  templateUrl: './work-with-us-benefits-and-training.component.html',
  styleUrls: ['./work-with-us-benefits-and-training.component.scss']
})
export class WorkWithUsBenefitsAndTrainingComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;
  workWithUsBenefitsAndTraining: any =
    {
      Nombre: 'Beneficios y formación',
      Ubicacion: '10',
      Contenido: [
        {
          title: 'Beneficios y formacion:',
          subtitle: 'Invertimos en nuestra fortaleza mas importante:',
          imageTitleMobile: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-title.png',
          imageTitleDesktop: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-title.png',
          imageAlt: 'Nuestra gente de sangre amarilla.',
          imageTitle: 'Nuestra gente de sangre amarilla.',
          description: 'Hemos creado un portafolio versatil centrado centrado en valorar el momento de vida y las necesidades de los colaboradores, bajo el lema "nuestro corazon palpita fuerte".',
          Fields: [
            {
              title:'Nuevo item',
              imageUrl:null,
              imageAlt:null,
              imageTitle:null,
              descriptionLeft: null,
              descriptionRight: null,
              imageRightUrl: null,
              imageRightAlt: null,
              imageRightTitle: null,
              icon: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-icon1.png',
              altIcon: 'Late Positivo',
              col: 'colSize',
              description: 'Programas que buscan aportar valor al colaborador en términos de estabilidad, integridad, física, seguridad y salud en el trabajo:',
              column2:'<ul ><li>Oilimpiadas deportivas</li><li>Programas de medicina preventiva y del trabajo</li><li>Actividades culturales y celebracion fechas especiales.</li></ul>'
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-icon2.png',
              altIcon: 'Ritmo Flexible',
              col: 'colSize',
              description: 'Alternativas que permiten al colaborador gestionar su tiempo y lugar de trabajo de manera felxible:',
              Fields: [
                {
                  item: 'Horarios felxibles'
                },
                {
                  item: 'Jornadas compartir en familia'
                }
              ]
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-icon3.png',
              altIcon: 'Emoción en familia',
              col: 'colSize',
              description: 'Desarrollo de actividades orientadas a proporcionar bienestar y dar soporte al núcleo familiar del colaborador:',
              Fields: [
                {
                  item: 'Vacaciones recreativas'
                },
                {
                  item: 'Chicos pilos Efecty'
                },
                {
                  item: 'Día de la niñez'
                },
                {
                  item: 'Convenios para entretenimiento'
                }
              ]
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-icon4.png',
              altIcon: 'Palpitar en Formación',
              col: 'colSize',
              description: 'Soluciones formativas enfocadas a promover el crecimiento integral del colaborador con proyección profesional y laboral.',
              image: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-universidad.png',
              altImage: 'Universidad Corporativa Efecty'
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/beneficios-y-formaciones-icon5.png',
              altIcon: 'Un solo sentir',
              col: '',
              description: '<strong>Convocatorias internas, promociones, movimientos verticales y horizontales</strong> con enfoque interdisciplinar, que permita al colaborador escalar dentro de nuestra organización.'
            }
          ]
        }
      ]
    }


  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.workWithUsBenefitsAndTraining = this.contenido;
    }
  }

}
