import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlynumbersDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keydown',['$event']) presionandoTeclas(event) {
    const consulta: RegExp = /^[0-9]*$/;
    var codes = [8,9,48,49,50,51,52,53,54,55,56,57];
    if(!codes.includes(event.keyCode)){
      event.preventDefault();
    }
  }

}
