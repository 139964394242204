import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';

@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.scss']
})
export class PqrComponent implements OnInit {

  pqr = {
    Nombre: 'Consultar PQR',
    Ubicacion: '26',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          icon: API_URI + 'uploads/images/Grupo 2017.png',
          title: 'Consultar PQR',
          description: 'Para realizar la consulta es importante que tengas disponible el número de radicación que se informó en el momento de realizar tu solicitud.'
        }
      },
      {
        Propiedad: 'form',
        Contenido: [
          {
            Propiedad: 'form1',
            titleform: 'Por favor selecciona el tipo de documento y registra el número de documento',
            Contenido: [
              {
                Propiedad: 'row1',
                Contenido: [
                  {
                    Propiedad: 'select',
                    name: 'tipodocumento',
                    Contenido: [
                      {
                        option: 'Cedula'
                      },
                      {
                        option: 'Otro'
                      }
                    ]
                  },
                  {
                    type: 'number',
                    Propiedad: 'input1',
                    name: 'numerodocumento',
                    label: '',
                    placeholder: 'Escribe tu número sin puntos ni comas'
                  }
                ]
              },
              {
                Propiedad: 'row2',
                Contenido: [
                  {
                    Propiedad: 'input2',
                    name: 'numerocaso',
                    type: 'text',
                    label: 'Número de caso:',
                    placeholder: 'Escribe tu número sin puntos ni comas'
                  }
                ]
              },
              {
                Propiedad: 'row2',
                Contenido: [
                  {
                    Propiedad: 'button'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Propiedad: 'table',
        Contenido: [
          {
            title: 'Número de radicado',
            description: ''
          },
          {
            title: 'Número de CUN',
            description: ''
          },
          {
            title: 'Nombre del cliente',
            description: ''
          },
          {
            title: 'Número de documento',
            description: ''
          },
          {
            title: 'Tipo de solicitud MINTIC',
            description: ''
          },
          {
            title: 'Tipo de solicitud',
            description: ''
          },
          {
            title: 'Fecha de creación',
            description: ''
          },
          {
            title: 'Fecha estimada de solución',
            description: ''
          },
          {
            title: 'Estado de tramite',
            description: ''
          },
          {
            title: 'Estado de tramite SIC',
            description: ''
          },
          {
            title: 'Fecha de respuesta',
            description: ''
          },
          {
            title: 'Descarga respuesta',
            description: ''
          },
        ]
      }
    ]
  };

  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  };
  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image){
      this.image = image;
      this.active = true;
    }
  };

  dataTable = [];

  formDocument: FormGroup;
  formNumber: FormGroup;
  listado: any;
  documentos;


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService,

  ) {
    this.loadData();
   }

   async loadData () {
    await this.apiService.getJSON();
    this.apiService.getTipoDocumento().subscribe(
      response => {
        this.documentos = response;
      },
      err => {
      }
    );
  }

  ngOnInit() {
    this.formDocument = this.formBuilder.group({
      select: 'Seleccione una opción...',
      tipodocumento: [null, []],
      numerodocumento: [null, []],
      numerocaso: [null, []],
    });

  }

  async submitDocument() {
    this.loading.show( 'assets/images/loading.gif');
    if(this.formDocument.invalid){
      this.modal.show ('assets/images/icon-modal-error.png',
      'assets/images/title-modal-error.png',
      'Todos los campos son obligatorios, ' + this.getFormValidationErrors(this.formDocument));
      this.loading.hidde();
      return;
    }
    const datos = this.formDocument.value;
    await this.apiService.getJSON();
    this.apiService.postConsultaPQRNatural(datos.tipodocumento, datos.numerodocumento, datos.numerocaso).subscribe(
      (result:any) => {
        this.dataTable = result;
        if(result.length == 0){
          this.modal.show (
            'assets/images/icon-modal-error.png',
            'assets/images/title-modal-error.png', 'No se encontraron resultados');
        }
        this.loading.hidde();

      },
      err => {
        this.loading.hidde();
        this.modal.show (
          'assets/images/icon-modal-error.png',
          'assets/images/title-modal-error.png', err.message);
      }

    );
  }

  descargar(item){
    if(item){
      window.open(item.ContenidoArchivo);
    }
  }

  getFormValidationErrors(form) {
    let textErrors = '';
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          textErrors += ' ' + key + ', ' + keyError + ', ', controlErrors[keyError];
        });
      }
    });
    return textErrors;
  }
}
