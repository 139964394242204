import { Component, OnInit,  ViewChild, ElementRef } from '@angular/core';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-format-validator',
  templateUrl: './format-validator.component.html',
  styleUrls: ['./format-validator.component.scss']
})
export class FormatValidatorComponent implements OnInit {

  formatValidator = {
    Nombre: 'Validador de formatos',
    Ubicacion: '8',
    Contenido: [
      {
        Contenido: {
          labelSelect: 'Selecciona el tipo de archivo que desea validar',
          Contenido: [
            {
              option: ''
            }
          ],
          labelFile: 'Selecciona el archivo a ser validado',
          buttonFile: 'Seleccionar archivo',
          placeholderFile: 'No eligio ningun archivo',
          labelText: 'Ingresa el resultado',
          button: 'Validar'
        }
      }
    ]
  }

  data;

  constructor() { }

  ngOnInit() {
    this.data = {

      inputText: {
        key: 'idd1',
        type: 'text',
        Placeholder: 'test jholder'
      },
      selectInput:{
        key: 'idd1',
        type: 'text',
        optionsArray:[
          {
            key: 'idd1',
            value: 'text',
          },
          {
            key: 'idd21',
            value: 'tsdsdext',
          }
        ]
      }
    }
  }

  handleFileInput(file){
    $('#labelFile').text(file[0].name)
  }

}
