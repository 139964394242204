import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.scss']
})
export class SustainabilityComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  sustainability: any =
    {
      Nombre: 'Sostenibilidad',
      Ubicacion: '2',
      Contenido: [
        {
          Propiedad: 'header',
          Contenido:
          {
            icon: API_URI+'uploads/images/Grupo 1875.png',
            imageTitle: API_URI+'uploads/images/sostenibilidad-titulo.png'
          }
        },
        {
          Propiedad: 'body',
          Contenido: [
            {
              Propiedad: 'col1',
              image: API_URI+'uploads/images/sostenibilidad.png'
            },
            {
              Propiedad: 'col2',
              description: 'Nos satisface compartir con ustedes, nuestros accionistas, colaboradores empresariales, clientes, proveedores, colaboradores internos, entidades departamentales y órganos de gobierno, los logros que obtuvimos durante 2016 y 2017, así como los resultados que evidencian nuestro compromiso y aporte a la sostenibilidad económica, social y ambiental en todos los territorios donde hacemos presencia. Durante estos dos años, nuestros esfuerzos se enfocaron en culminar con éxito las actividades asociadas a la ejecución de nuestro portafolio de servicios, objetivo que materializamos con la displinay el compromiso ético y profesional de los que hacemos parte de la compañía, logrando así, la consolidación de una empresa rentable y sostenible, que trabaja por la prestación de un servicio eficiente y comprometido con la innovación a través de una opercaión sostenible y responsable con el medio ambiente, con una gestión íntegra, transparente y basada en las mejores prácticas para el desarrollo del talento.'
            }
          ]
        },
        {
          Propiedad: 'button',
          Contenido: {
            buttonText: 'Descarga el informe de sostenibilidad',
            buttonLink: '#'
          }
        }
      ]
    }



  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.sustainability = this.contenido;
    }

  }

}
