import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-columns',
  templateUrl: './three-columns.component.html',
  styleUrls: ['./three-columns.component.scss']
})
export class ThreeColumnsComponent implements OnInit {

  threeColumns = {
    Nombre: 'Tres columnas',
    Ubicacion: '1',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          imageTitle: API_URI+'uploads/images/como-hacer-un-pago.png',
          altImage: ''
        }
      },
      {
        Propiedad: 'hero',
        Contenido: [
          {
            icon: API_URI+'uploads/images/Grupo 1710.png',
            title: "<h3 class='mg-bottom-xs-15 mg-bottom-md-30'>Verifica<br>tu factura</h3>",
            description: "<p class='mg-bottom-xs-30 mg-bottom-md-30'>Conoce si puedes pagar<br> tu factura en Efecty.</p>",
            buttonText: 'Convenios',
            buttonLink: '/convenios'
          },
          {
            icon: API_URI+'uploads/images/Grupo -80.png',
            title: "<h3 class='mg-bottom-xs-15 mg-bottom-md-30'>Acercate a un<br> punto de atneción</h3>",
            description: "<p class='mg-bottom-xs-30 mg-bottom-md-30'>Busca el punto<br> más cercano.</p>",
            buttonText: 'Puntos de atencion',
            buttonLink: '/puntos-de-atencion'
          },
          {
            icon: API_URI+'uploads/images/Grupo 1708.png',
            title: "<h3 class='mg-bottom-xs-15 mg-bottom-md-30'>Realiza <br>el pago</h3>",
            description: "<p class='mg-bottom-xs-30 mg-bottom-md-30'>Entrega el dinero a nuestros<br> especialistas de servicio.</p>",
            buttonText: 'Recomendaciones de seguridad',
            buttonLink: '/recomendaciones-de-seguridad'
          }
        ]
      }
    ]
  }

  constructor() { }

  ngOnInit() {
  }

}
