import { ComponentBase } from './../../_models/component-base';
import { Content } from './../../_models/content';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-carousel',
  templateUrl: './modal-carousel.component.html',
  styleUrls: ['./modal-carousel.component.scss']
})
export class ModalCarouselComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  modalCarousel;

  slideConfig = {
    "slidesToShow": 1,
    'slidesToScroll': 1,
    "responsive": [
      {
        'breakpoint': 960,
        'settings': {
          "slidesToShow": 1,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 720,
        'settings': {
          "slidesToShow": 1,
          'slidesToScroll': 1
        }
      }
    ],
    "infinite": true,
    "autoplay": true
  }

  constructor() { }

  ngOnInit() {
    this.modalCarousel = this.contenido;
  }

}
