import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-banner-main-simple',
  templateUrl: './banner-main-simple.component.html',
  styleUrls: ['./banner-main-simple.component.scss']
})
export class BannerMainSimpleComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url: any;
  activeModal = true;


  constructor() { }

  data: any = [
    {
      tituloImageUrl: 'uploads/images/atencion-al-cliente-titulo.png',
      tituloImageAlt: 'uploads/images/atencion-al-cliente-titulo.png',
      tituloImageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
      subtituloImageUrl: 'uploads/images/atencion-al-cliente-titulo.png',
      subtituloImageAlt: 'uploads/images/atencion-al-cliente-titulo.png',
      subtituloImageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
      description: '<b>Entre más giros</b> y pagos realices, más monedas acumularas para redimirlas...',
      botonUrl: '#',
      botonText: 'Regístrate',
      imageLeftUrl: 'uploads/images/condiciones-para-el-cobro-de-un-giro.png',
      imageLeftAlt: 'uploads/images/condiciones-para-el-cobro-de-un-giro.png',
      imageLeftTitle: 'uploads/images/condiciones-para-el-cobro-de-un-giro.png',
      position: '0'
    }
  ];

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.data = this.contenido.Contenido;
    }

  }

}
