import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-cms-payty',
  templateUrl: './cms-payty.component.html',
  styleUrls: ['./cms-payty.component.scss']
})
export class CmsPaytyComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  cmsPayty: any = {
    Nombre: 'Payty',
    Ubicacion: '27',
    Contenido:[
      {
        imageUrlDesktop: 'uploads/images/banner_home/empresaPayty2.png',
        imageAlt: 'Payty',
        imageTitle: 'Payty',
        Fields: [
          {
            titleImageMobile: '',
            titleImageDesktop: '',
            Propiedad: 'hero1',
            title: 'Pasarela de pagos para aumentar tus Ventas',
            description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Explicabo cum et totam ipsam qui eligendi. Perspiciatis consequatur consequuntur corrupti exercitationem quas dolorum cum, eaque amet esse veniam eos ipsum totam.',
            buttonText: 'Conoce más',
            buttonLink: '#'
          },
          {
            titleImageMobile: '',
            titleImageDesktop: '',
            Propiedad: 'hero2',
            title: '¿Por qué elegir Payty?',
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ex adipisci sint voluptatem at ipsam voluptatibus dolorum animi aspernatur, alias aut, aliquam deleniti labore odio illo vero id quibusdam, consectetur veniam.',
            buttonText: 'Solicita tu pasarela',
            buttonLink: '#'
          }
        ]
      }
    ]
  }

  color = false;

  constructor() { }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.cmsPayty = this.contenido;
    }
  }

  toogleClass(){
    this.color = this.color ? false: true;
    return this.color;
  }

}
