
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-form-submit-pqr',
  templateUrl: './form-submit-pqr.component.html',
  styleUrls: ['./form-submit-pqr.component.scss']
})
export class FormSubmitPqrComponent implements OnInit {

  @Input() api_url: any;

  formSubmitPqr = {
    Nombre: 'Radicar PQR',
    Ubicacion: '27',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          icon: this.api_url + 'uploads/images/Grupo 2028.png',
          title: 'Radicar PQR'
        }
      },
      {
        Propiedad: 'form',
        Contenido: {
          action: '/',
          Contenido: [
            {
              Propiedad: 'tabs',
              Contenido: [
                {
                  Propiedad: 'col1',
                  Contenido: {
                    title: 'Tipo de solicitud: *'
                  }
                },
                {
                  Propiedad: 'col2',
                  name: 'TipoSolicitudGUID',
                  type: 'radio',
                  Contenido: [
                    {
                        value: '7fd71174-9c1d-e911-a837-000d3a5377e1',
                        Codigo: 'TS05',
                        label: 'Petición'
                    }, {
                        value: '77d71174-9c1d-e911-a837-000d3a5377e1',
                        Codigo: 'TS01',
                        label: 'Queja'
                    }, {
                        value: '79d71174-9c1d-e911-a837-000d3a5377e1',
                        Codigo: 'TS02',
                        label: 'Indemnización'
                    }, {
                        value: '81d71174-9c1d-e911-a837-000d3a5377e1',
                        Codigo: 'TS06',
                        label: 'Sugerencia'
                    }
                  ]
                }
              ]
            },
            {
              Propiedad: 'fields',
              Contenido: [
                {
                  Propiedad: 'select',
                  label: 'Tipo de documento:*',
                  name: 'TipoDocumentoGUID',
                  Contenido: [
                    {
                      option: 'Cedula'
                    },
                    {
                      option: 'Otro'
                    }
                  ]
                },
                {
                  Propiedad: 'inputnum',
                  type: 'number',
                  label: 'Número de documento:*',
                  placeholder: 'Escribe tu número sin puntos ni comas',
                  name: 'NumeroDocumento',
                  pattern: ''
                },
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Nombres:',
                  placeholder: 'Escribe tu nombre completo',
                  name: 'Nombres',
                  pattern: ''
                },
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Apellidos:',
                  placeholder: 'Escribe tu apellido completo',
                  name: 'Apellidos',
                  pattern: ''
                },
                {
                  Propiedad: 'inputCel',
                  type: 'number',
                  label: 'Número de celular:*',
                  placeholder: 'Escribe los dígitos de tu télefono',
                  name: 'TelefonoCelular',
                },
                {
                  Propiedad: 'inputTel',
                  name: 'TelefonoFijo',
                  type: 'number',
                  label: 'Número de teléfono fijo',
                  placeholder: 'Escribe los 7 dígitos de tu télefono'
                },
                {
                  Propiedad: 'radio',
                  name: 'MedioRespuesta',
                  type: 'radio',
                  label: 'Medio de respuesta de PQR',
                  Contenido: [
                    {
                      label: 'Físico',
                      value: 1
                    },
                    {
                      label: 'Correo electrónico',
                      value: 2
                    }
                  ]
                },
                {
                  Propiedad: 'input',
                  type: 'text',
                  name: 'Direccion',
                  label: 'Dirección:*',
                  placeholder: 'Escribe tu dirección completa'
                },
                {
                  Propiedad: 'autocomplete',
                  label: 'Municipio:*',
                  placeholder: 'Escribe el municipio',
                  name: 'MunicipioGUID',
                },
                {
                  Propiedad: 'input',
                  type: 'email',
                  name: 'CorreoElectronico',
                  label: 'Correo electronico:*',
                  placeholder: 'Escribe tu correo personal o del negocio'
                },
                {
                  Propiedad: 'textarea',
                  name: 'Descripcion',
                  label: 'Descripción PQR:*',
                  labelDescription: '¿Cual es el objeto y los hechos que fundamenta su petición, queja indemnización o sugerencia?'
                },
                {
                  Propiedad: 'file',
                  type: 'file',
                  icon: this.api_url   + 'uploads/images/icon-upload-files.png',
                  label: 'Adjuntar archivos',
                  button: 'seleccionar archivo',
                  copyright: 'Recuerde que, si la solicitud es a nombre propio debe adjuntar copia de su cédula legible por ambas caras, si es a nombre de una empresa debe adjuntar Cámara de Comercio vigente, si es un tercero debe adjuntar poder vigente autenticado por notaria'
                }
              ]
            }
          ],
          AutorizaTratamientoDatosPersonales: 'Autoriza tratamiento de datos personales',
          policy: 'Conoce la política de datos aquí',
          button: 'Enviar'
        }
      }
    ]
  };

  pageForm: FormGroup;
  municipioEstatus = false;
  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    codigopqr: '',
    despqr: 'mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message, codigopqr, despqr){
      this.image = image;
      this.title = title;
      this.text = message;
      this.codigopqr = codigopqr;
      this.despqr = despqr;
      this.active = true;
    }
  }

  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image){
      this.image = image;
      this.active = true;
    }
  };

  tipos;
  documentos;
  motivos;
  municipios;
  municipioBuscado;
  imageSrc;
  files = null;
  mostrarAutocomplete = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService,

  ) {
    this.loadData();
   }

   async loadData() {
    this.loading.show( 'assets/images/loading.gif');
    await this.apiService.getJSON();
    this.apiService.getTipoSolicitud().subscribe(
      response => {
        this.tipos = response;
        this.loading.hidde();
      },
      err => {

      }
    );

    this.apiService.getTipoDocumento().subscribe(
      response => {
        this.documentos = response;
        this.loading.hidde();
      },
      err => {

      }
    );

    this.apiService.getMotivo().subscribe(
      response => {
        this.motivos = response;
      },
      err => {

      }
    );

  }

  ngOnInit() {

    this.pageForm = this.formBuilder.group(
      {
        TipoSolicitudGUID: ['', Validators.required],

        Descripcion: ['', Validators.required],
        MedioRespuesta: ['', Validators.required],
        TipoDocumentoGUID: ['', Validators.required],
        NumeroDocumento: ['', [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15)
        ]],
        Nombres: ['',
        Validators.compose([
          Validators.required,
          Validators.maxLength(250),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ])
      ],
        Apellidos: ['',
        Validators.compose([
          Validators.required,
          Validators.maxLength(250),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ])
      ],
        TelefonoCelular: ['', [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15)
        ]],
        TelefonoFijo: ['', [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15)
        ]],
        Direccion: ['', Validators.required],
        MunicipioGUID: ['', Validators.required],
        CorreoElectronico: ['',  Validators.compose([
            Validators.maxLength(50),
            Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
            Validators.email
          ])
        ],
        AutorizaTratamientoDatosPersonales:  ['', Validators.required],
      }
    );
    this.pageForm.get('TipoDocumentoGUID').valueChanges.subscribe(val => {
      this.validarContacto(val);
    });
  }

  validarContacto(val) {
    this.loading.show( 'assets/images/loading.gif');
    const tipoValido = this.pageForm.get('TipoDocumentoGUID').valid;
    const documentoValido = this.pageForm.get('NumeroDocumento').valid;
    if (tipoValido && documentoValido) {
      const tipoDocumento = this.pageForm.get('TipoDocumentoGUID').value;
      const numeroDocumento = this.pageForm.get('NumeroDocumento').value;
      this.apiService.getContacto(tipoDocumento, numeroDocumento).subscribe(
        (response: any) => {
          if (response.length == 1) {
            const data = response[0];
            console.log(data);
            this.pageForm.get('Nombres').setValue(data.Nombres);
            this.pageForm.get('Apellidos').setValue(data.Apellidos);
            this.pageForm.get('TelefonoCelular').setValue(data.TelefonoCelular);
            this.pageForm.get('TelefonoFijo').setValue(data.TelefonoFijo);
            this.pageForm.get('Direccion').setValue(data.Direccion);
            this.pageForm.get('MunicipioGUID').setValue(data.MunicipioGUID);
            this.pageForm.get('CorreoElectronico').setValue(data.CorreoElectronico);
            this.municipioBuscado = data.Municipio;

            this.pageForm.get('Nombres').disable();
            this.pageForm.get('Apellidos').disable();
            this.pageForm.get('TelefonoCelular').disable();
            this.pageForm.get('TelefonoFijo').disable();
            this.pageForm.get('Direccion').disable();
            this.pageForm.get('MunicipioGUID').disable();
            this.pageForm.get('CorreoElectronico').disable();
            this.municipioEstatus = true;

          } else {
            this.pageForm.get('Nombres').enable();
            this.pageForm.get('Apellidos').enable();
            this.pageForm.get('TelefonoCelular').enable();
            this.pageForm.get('TelefonoFijo').enable();
            this.pageForm.get('Direccion').enable();
            this.pageForm.get('MunicipioGUID').enable();
            this.pageForm.get('CorreoElectronico').enable();
            this.municipioEstatus = false;
          }
          this.loading.hidde();
        },
        err => {
          this.modal.show (
            'assets/images/icon-modal-error.png',
            'assets/images/title-modal-error.png',
            '',
            '',
            err.message,
            );
          this.loading.hidde();
        }
      );
    } else {
      this.loading.hidde();
    }
  }

  async submit() {
    this.loading.show( 'assets/images/loading.gif');
    if (this.pageForm.invalid) {
      this.modal.show (
        'assets/images/icon-modal-error.png',
        'assets/images/title-modal-error.png',
        'Todos los campos del formulario son requeridos, ',
        '',
        this.getFormValidationErrors(),
      );
      this.loading.hidde();
      return false;
    }

    this.pageForm.get('Nombres').enable();
    this.pageForm.get('Apellidos').enable();
    this.pageForm.get('TelefonoCelular').enable();
    this.pageForm.get('TelefonoFijo').enable();
    this.pageForm.get('Direccion').enable();
    this.pageForm.get('MunicipioGUID').enable();
    this.pageForm.get('CorreoElectronico').enable();

    const toSend = this.pageForm.value;

    toSend.Contacto = {
      TipoDocumentoGUID: toSend.TipoDocumentoGUID,
      TipoDocumento: null,
      NumeroDocumento: toSend.NumeroDocumento,
      Nombres: toSend.Nombres,
      Apellidos: toSend.Apellidos,
      TelefonoFijo: toSend.TelefonoFijo,
      TelefonoCelular: toSend.TelefonoCelular,
      Direccion: toSend.Direccion,
      MunicipioGUID: toSend.MunicipioGUID,
      CorreoElectronico: toSend.CorreoElectronico
    };

    toSend.NotaAdjunta = this.files;

    await this.apiService.getJSON();
    this.apiService.postCrearPQRNatural(this.pageForm.value).subscribe(
      (response: any) => {
        this.modal.show (
          'assets/images/icon-modal-send.png',
          'assets/images/title-modal-atencion.png',
          '<span class="mensaje">Con el fin de tramitar tu solicitud te informamos que será gestionada con el siguiente número de caso: </span>',
           response.NumeroRadicado,
          '<strong>Recuerda tener presente el número de caso.</strong><br><span>Gracias por contactarnos, esperamos darte respuesta lo más pronto posible</span><br><br>');
          this.loading.hidde();
          this.resetForm();
      },
      err => {
        this.modal.show (
          'assets/images/icon-modal-error.png',
          'assets/images/title-modal-error.png',
          '',
          '',
          err.message,
          );
        this.loading.hidde();
      }
    );
  }

  getFormValidationErrors() {
    let textErrors = '';
    Object.keys(this.pageForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.pageForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          textErrors += '<br>' + key + ' es un campo requerido';
        });
      }
    });
    return textErrors;
  }

  set municipio(municipio){
    this.municipioBuscado = municipio;
    this.pageForm.controls.MunicipioGUID.setValue(municipio);
    this.apiService.getMunicipios(this.municipioBuscado).subscribe(
      response => {
        this.municipios = response;
        if(this.municipios.length > 0){
          this.mostrarAutocomplete = true;
        }
      },
      err => {
        this.mostrarAutocomplete = false;
      }
    );
  }

  seleccionarMunicipio(item){
    this.municipioBuscado = item.Nombre;
    this.pageForm.controls.MunicipioGUID.setValue(item.GUID);
    this.mostrarAutocomplete = false;
  }

  get municipio(){
    return this.municipioBuscado;
  }

  handleFileInput(fileInput) {
    let fileData = [];
    fileData = fileInput.target.files;
    for (let i= 0; i< fileData.length; i++) {
      this.uploadDocument(fileData[i]);
    }
  }
  uploadDocument(file) {
    let fileReader = new FileReader();
    fileReader.onload = (readerEvt) => {
      const objetivo: any = readerEvt.target;
      const contentFile = btoa(objetivo.result.toString());
      this.addFile(contentFile, file);
    }
    fileReader.readAsBinaryString(file);
  }

  addFile(contenFile, file){
    file = {
      Texto: file.name,
      ContenidoArchivo:contenFile,
      NombreArchivo: file.name,
      MimeType: file.type,
      TamanioArchivo: file.size,
      FechaCreacion: null
    }

    this.files = file;
  }

  passFile(fileData) {
    for ( let i= 0; i <fileData.length; i++ ) {
      this.uploadDocument(fileData[i]);
    }
  }

  delFile() {
    this.files = null;
  }

  resetForm() {
    this.pageForm.get('Nombres').setValue('');
    // this.pageForm.get('TipoSolicitudGUID').setValue('');
    this.pageForm.get('Descripcion').setValue('');
    // this.pageForm.get('MedioRespuesta').setValue('');
    this.pageForm.get('TipoDocumentoGUID').setValue('');
    this.pageForm.get('NumeroDocumento').setValue('');
    this.pageForm.get('Nombres').setValue('');
    this.pageForm.get('Apellidos').setValue('');
    this.pageForm.get('TelefonoCelular').setValue('');
    this.pageForm.get('TelefonoFijo').setValue('');
    this.pageForm.get('Direccion').setValue('');
    this.pageForm.get('MunicipioGUID').setValue('');
    this.pageForm.get('CorreoElectronico').setValue('');
    // this.pageForm.get('AutorizaTratamientoDatosPersonales').setValue();
  }

}
