import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attention-client',
  templateUrl: './attention-client.component.html',
  styleUrls: ['./attention-client.component.scss']
})
export class AttentionClientComponent implements OnInit {

  attentionClient =
    {
      Nombre: 'Atención al cliente',
      Ubicacion: '15',
      Contenido: [
        {
          Propiedad: 'col1',
          Contenido:
          {
            icon: API_URI+'uploads/images/Grupo -6.png',
            titleImage: API_URI+'uploads/images/atencion-al-cliente-titulo.png',
            alt: 'Atención al cliente',
            subtitle: 'Centro de contactos',
            description: "<p>Línea gratuita nacional: <strong>018000 010101</strong></p><p> Bogotá: <strong> 6510101 </strong></p><p>info@4e fecty.com.co</p>",
            buttonText: 'Más líneas de atención',
            buttonLink: 'djsjd'
          }
        },
        {
          Propiedad: 'col2',
          Contenido: {
            title: 'En Efecty nos encanta ayudarte',
            subtitle: '¿Estas listo para una nueva experiencia?',
            image: API_URI+'uploads/images/servicioalcliente.png',
          }
        }
      ]
    }

  constructor() { }

  ngOnInit() {
  }

}
