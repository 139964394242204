import { Component, Input, OnInit, Output }  from '@angular/core';
import { FormGroup }                 from '@angular/forms';
import { FieldsBase }              from '../../class/fields-base';
import { FieldsControlService }    from '../../service/fields-control.service'; 
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
  
@Component({
  selector: 'app-dynamic-form-container',
  templateUrl: './dynamic-form-container.component.html',
  styleUrls: ['./dynamic-form-container.component.scss'],
  providers: [ FieldsControlService ]

})

export class DynamicFormContainerComponent implements OnInit {
  /**
  * Contiene la lista dinamica de inputs
  * @property {FieldsBase<any>[]}
  */
  @Input() questions: FieldsBase<any>[] = [];


  /**
   * Emisor de evento de cambios en el formulario
   */
  @Output() changeValues: EventEmitter<any> = new EventEmitter();
  // @Output() changeValue: EventEmitter<any> = new EventEmitter();

  /**
  * Almacena la lista de inputs
  * @property {FormGroup}
  */
  form: FormGroup;

  /**
  * Datos del form
  * @property {string}
  */
  payLoad = '';

  constructor(private qcs: FieldsControlService){
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions);
    this.form.valueChanges.subscribe(change => this.changeValues.emit(change));
  }

  onSubmit() {
    this.payLoad = this.form.value;
  }

}
