import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-work-with-us-we-are-characterized-by',
  templateUrl: './work-with-us-we-are-characterized-by.component.html',
  styleUrls: ['./work-with-us-we-are-characterized-by.component.scss']
})
export class WorkWithUsWeAreCharacterizedByComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  workWithUsWeAreCharacterizedBy: any =
    {
      Nombre: 'Nos caracterizamos por',
      Ubicacion: '7',
      Contenido: [
        {
          title: 'Nos caracterizamos por:',
          imageTitleMobile: 'uploads/images/nos-caracterizamos-por-mobile.png',
          imageTitleDesktop: 'uploads/images/recargas/work-with-us-ttt.png',
          alt: 'Ser Gente feliz, innovadora y perdurable',
          subtitle: 'Lo logramos a través de los pilares:',
          Fields: [
            {
              icon: 'uploads/images/trabaja-con-nosotros/nos-caracterizamos-por-icon-1.png',
              title: 'Atracción',
              desc: 'Identificamos y conquistamos talentos para enfrentar los desafíos del mercado, posicionándonos en el país como una marca empleadora.'
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/nos-caracterizamos-por-icon-2.png',
              title: 'Desarrollo',
              desc: 'Orientamos nuestros esfuerzos hacia el desarrollo integral de talento humano, a través de herramientas que fortalecen sus habilidades y competencias.'
            },
            {
              icon: 'uploads/images/trabaja-con-nosotros/nos-caracterizamos-por-icon-3.png',
              title: 'Bienestar',
              desc: 'Fomentamos el balance entre la vida familiar, personal y laboral de nuestros colaboradores a través de programas de salud y bienestar.'
            },
          ]
        }
      ]
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.workWithUsWeAreCharacterizedBy = this.contenido;
    }

  }

}
