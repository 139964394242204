import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-historical-review',
  templateUrl: './historical-review.component.html',
  styleUrls: ['./historical-review.component.scss']
})
export class HistoricalReviewComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  historicalReview: any =
    {
      Nombre: 'Reseña historica',
      Ubicacion: '3',
      Contenido: [
        {
          imageAlt: 'Reseña histórica',
          imageTitle: 'Reseña histórica',
          imageTitleMobile: 'uploads/images/resena-historica-titulo-movil.png',
          imageTitleDesktop: 'uploads/images/resena-historica-titulo.png',
          Fields: [
            {
              yearSince: '1996 - 2000',
              description: '<ul>'+
                '<li>Efecty se crea bajo la razón social de Efectivo S.A, el 04 de Diciembre de 1996 como pioneros en el traslado de dinero debido a que los clientes de nuestro aliado estratégico Servientrega enviaban su dinero por encomiendas.</li>'+
                '<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>'+
                '<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>'+
              '</ul>'
            },
            {
              yearSince: '2001 - 2005',
              description: '<ul>'+
                '<li>Única razón social y se crean dos empresas llamadas Efectivo Ltda. y Circulante S.A.</li>'+
                '<li>Única razón social y se crean dos empresas llamadas Efectivo Ltda. y Circulante S.A.</li>'+
                '<li>Única razón social y se crean dos empresas llamadas Efectivo Ltda. y Circulante S.A.</li>'+
              '</ul>'
            },
            {
              yearSince: '2006 - 2010',
              description: '<ul>'+
                '<li>Efecty recibe la certificación de calidad ISO 9001:2008 en diseño y desarrollo para los productos recuado, pago y giro de dinero, en Dirección General y 3 PAP directos.</li>'+
                '<li>Efecty recibe la certificación de calidad ISO 9001:2008 en diseño y desarrollo para los productos recuado, pago y giro de dinero, en Dirección General y 3 PAP directos.</li>'+
                '<li>Efecty recibe la certificación de calidad ISO 9001:2008 en diseño y desarrollo para los productos recuado, pago y giro de dinero, en Dirección General y 3 PAP directos.</li>'+
                '<li>Efecty recibe la certificación de calidad ISO 9001:2008 en diseño y desarrollo para los productos recuado, pago y giro de dinero, en Dirección General y 3 PAP directos.</li>'+
              '</ul>'
            },
            {
              yearSince: '2006 - 2010',
              description: '<ul>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
                '<li>Se amplia el alcance de la certificación en calidad ISO 9001:2008 a todas las regionales y PAP a nivel nacional.</li>'+
              '</ul>'
            },
            {
              yearSince: '2016 - 2020',
              description: '<ul>'+
                '<li>Actualmente Efecty cuenta con más de 8719 PAP en más de 1075 municipios a nivel nacional.</li>'+
                '<li>Actualmente Efecty cuenta con más de 8719 PAP en más de 1075 municipios a nivel nacional.</li>'+
                '<li>Actualmente Efecty cuenta con más de 8719 PAP en más de 1075 municipios a nivel nacional.</li>'+
                '<li>Actualmente Efecty cuenta con más de 8719 PAP en más de 1075 municipios a nivel nacional.</li>'+
                '<li>Actualmente Efecty cuenta con más de 8719 PAP en más de 1075 municipios a nivel nacional.</li>'+
              '</ul>'
            }
          ]
        }
      ]
    }
    activeIdString = '0';

    constructor() {
     }

    activeTab(index) {
      this.activeIdString = index.toString();
    }

    ngOnInit() {
      if(this.contenido != undefined && this.contenido.Contenido.length != 0){
        this.historicalReview = this.contenido;
      }

    }

  }
