import { BehaviorSubject } from 'rxjs';
import { TemplateServiceService } from '../_services/template-service.service';
import { Page } from '../_models/page';
import { ComponentBase } from '../_models/component-base';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Almacen {

  private googletagid = new BehaviorSubject(localStorage.getItem('googletagid'));
  currentGoogletagid = this.googletagid.asObservable();

  private base = new BehaviorSubject(null);
  currentBase = this.base.asObservable();


  constructor(
    private api: TemplateServiceService
  ) { }

  async loadBase() {
    await this.api.getJSON();
    this.api.getBase().subscribe((data: Page) => this.setDataComponents(data));
  }

  async setDataComponents(data: Page){
    await this.api.getJSON();
    data.configurations = {};
    data.components.forEach((component, i) =>{
      data.configurations[component.Nombre] = ComponentBase.setPropiedades(component.Contenido)
    });
    this.base.next(data.configurations);
    if(data.configurations.Settings){
      this.changeGoogletagid(data.configurations.Settings[0].googletagid);
    }
  }

  changeGoogletagid(value: string) {
    if (this.googletagid.value !== value){
      localStorage.setItem('googletagid', value);
      this.googletagid.next(value);
      window.location.reload();
    }
  }
}
