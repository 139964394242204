import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-sidebar-tabs',
  templateUrl: './sidebar-tabs.component.html',
  styleUrls: ['./sidebar-tabs.component.scss']
})
export class SidebarTabsComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  sidebarTabs: any = {
    Nombre: 'Recomendaciones de seguridad',
    Ubicacion: '28',
    Contenido: [
      {
        title: 'Tab 1',
          description: 'Raw denim you probably haven\'t heard of them jean shorts Austin.Nesciunt tofu stumptown aliqua, retro synth master cleanse.Mustache cliche tempor, williamsburg carles vegan helvetica.Reprehenderit butcher retro keffiyeh dreamcatcher synth.Cosby sweater eu banh mi, qui irure terry richardson ex squid.Aliquip placeat salvia cillum iphone.Seitan aliquip  quis cardigan american apparel, butcher voluptate nisi qui.'
      },
      {
        title: 'Tab 2',
        image: 'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        alt: 'Facilidad',
        description: '<h5><strong>¿Qué es?</strong></h5><p>Exercitation +1 labore velit, blog sartorial PBR leggings nextlevel wes anderson artisan four loko farm- to - table craft beertwee.Qui photo booth letterpress, commodo enim craft beermlkshk aliquip jean shorts ullamco ad vinyl cillum PBR.Homonostrud organic, assumenda labore aesthetic magna delectusmollit.Keytar helvetica VHS salvia yr, vero magna velitsapiente labore stumptown.Vegan fanny pack odio cillum wesanderson 8-bit, sustainable jean shorts beard ut DIY ethicalculpa terry richardson biodiesel.Art party scenesterstumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>'
      },
      {
        title: 'Tab 3',
        image: 'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        alt: 'Facilidad',
        description: 'Raw denim you probably haven\'t heard of them jean shortsAustin.Nesciunt tofu stumptown aliqua, retro synth mastercleanse.Mustache cliche tempor, williamsburg carles veganhelvetica.Reprehenderit butcher retro keffiyeh dreamcatchersynth.Cosby sweater eu banh mi, qui irure terry richardson ex squid.Aliquip placeat salvia cillum iphone.Seitanaliquip quis cardigan american apparel, butcher voluptate',
        Fields: [
          {
            imageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
            alt: 'Título sistema...',
            description: 'Raw denim you probably havent heard of them jean shorts Austin.Nesciunt tofu stumptown aliqua, retro synth master cleanse.Mustache cliche tempor, williamsburg carles vegan helvetica.'
          },
          {
            imageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
            alt: 'Título sistema...',
            description: 'Raw denim you probably havent heard of them jean shorts Austin.Nesciunt tofu stumptown aliqua, retro synth master cleanse.Mustache cliche tempor, williamsburg carles vegan helvetica.'
          },
          {
            title: '¿Cuáles son los canales...?',
            imageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
            alt: 'Título sistema...',
            description: 'Raw denim you probably havent heard of them jean shorts Austin.Nesciunt tofu stumptown aliqua, retro synth master cleanse.Mustache cliche tempor, williamsburg carles vegan helvetica.'
          }
        ]
      },
      {
        type: '4',
        title: 'Tab 4',
        Fields: [
          {
            title: '¿Cuáles son los canales...?',
            description: 'Anim pariatur cliche reprehenderit, enim eiusmod high lifeaccusamus terry richardson ad squid. 3 wolf moon officiaaute, non cupidatat skateboard dolor brunch.Food truckquinoa nesciunt laborum eiusmod.Brunch 3 wolf moon tempsunt aliqua put a bird on it squid single- origin coffeenulla assumenda shoreditch et.Nihil anim keffiyehhelvetica, craft beer labore wes anderson cred nesciuntsapiente ea proident.Ad vegan excepteur butcher vice lomo.Leggings occaecat craft beer farm - to - table, raw denimaesthetic synth nesciunt you probably haven\'t heard of themaccusamus labore sustainable VHS.'
          },
          {
            title: '¿Cuáles son los requisitos...?',
            description: 'Anim pariatur cliche reprehenderit, enim eiusmod high lifeaccusamus terry richardson ad squid. 3 wolf moon officiaaute, non cupidatat skateboard dolor brunch.Food truckquinoa nesciunt laborum eiusmod.Brunch 3 wolf moon tempsunt aliqua put a bird on it squid single- origin coffeenulla assumenda shoreditch et.Nihil anim keffiyehhelvetica, craft beer labore wes anderson cred nesciuntsapiente ea proident.Ad vegan excepteur butcher vice lomo.Leggings occaecat craft beer farm - to - table, raw denimaesthetic synth nesciunt you probably haven\'t heard of themaccusamus labore sustainable VHS.'
          },
          {
            title: '¿En cuánto tiempo...?',
            description: 'Anim pariatur cliche reprehenderit, enim eiusmod high lifeaccusamus terry richardson ad squid. 3 wolf moon officiaaute, non cupidatat skateboard dolor brunch.Food truckquinoa nesciunt laborum eiusmod.Brunch 3 wolf moon tempsunt aliqua put a bird on it squid single- origin coffeenulla assumenda shoreditch et.Nihil anim keffiyehhelvetica, craft beer labore wes anderson cred nesciuntsapiente ea proident.Ad vegan excepteur butcher vice lomo.Leggings occaecat craft beer farm - to - table, raw denimaesthetic synth nesciunt you probably haven\'t heard of themaccusamus labore sustainable VHS.'
          }
        ]
      }
    ]
  }

  activeIdString = '0';

  constructor() {
   }

  activeTab(index) {
    this.activeIdString = index.toString();
  }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.sidebarTabs = this.contenido;
    }

  }

}
