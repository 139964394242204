import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { Page } from 'src/app/_models/page';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  API_URI = API_URI;
  @Input() page: Page;
  constructor() { }

  ngOnInit() {
  }

}
