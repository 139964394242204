import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  map = {
    Nombre: 'Mapa',
    Ubicacion: '27',
    Contenido: {
      map: 'https://www.efecty.com.co/MapaPAP/'
    }
  }

  mapUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.mapUrl = this.safeMap(this.map.Contenido.map);
   }

  ngOnInit() {

  }

  safeMap(url){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
