import { Component, OnInit, Input } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {
  @Input() contenido:any;
  @Input() api_url:any;

  agreements = {
    Nombre: 'Covenios',
    Ubicacion: '25',
    Contenido: [
      {
          title: 'Busca la empresa a la cuál debes realizar tu pago:',
          Fields : [
            {
              name: 'Actitud Positiva',
              sector: ' Otros'
            },
            {
              name: 'Activos y Finanzas',
              sector: ' Financieros'
            },
            {
              name: 'Actuar Tolima',
              sector: ' Otros'
            },
            {
              name: 'Acuacentro AAA S.A. ESP.',
              sector: ' Servicios Públicos'
            }
          ]
        }
      ]
    }

  listaFiltrada = this.agreements.Contenido[0].Fields;
  empresas = [];
  sectores = [] ;
  palabraClaveValor: string = '';

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.agreements = this.contenido;
      this.listaFiltrada = this.agreements.Contenido[0].Fields;
      this.unicosEmpresa();
    }

  }

  unicosEmpresa(){
    this.listaFiltrada.forEach(
      (value, index) => {
        if(this.empresas.indexOf(value.name) == -1){
          this.empresas.push(value.name);
        }
        if(this.sectores.indexOf(value.sector) == -1){
          this.sectores.push(value.sector);
        }
      }
    );
  }

  filtrarEmpresa(aguja){
    let sectores = this.agreements.Contenido[0].Fields.filter(item =>{
      return item.name == aguja;
    });
    this.listaFiltrada = sectores;
  }

  get palabraClave(){
    return this.palabraClaveValor;
  }

  set palabraClave(valor: string){
    this.palabraClaveValor = valor;
    this.buscarEmpresa(valor);
  }

  buscarEmpresa(aguja){
    let sectores = this.agreements.Contenido[0].Fields.filter(item =>{
      return item.name.toLowerCase().includes(aguja.toLowerCase());
    });
    this.listaFiltrada = sectores;
  }

  filtrarSector(aguja){
    let sectores = this.agreements.Contenido[0].Fields.filter(item =>{
      return item.sector == aguja;
    });
    this.listaFiltrada = sectores;
  }

}
