
import { formatNumber } from '@angular/common';

import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from 'src/app/_services/templates.service';

@Component({
  selector: 'app-cms-products-and-services',
  templateUrl: './cms-products-and-services.component.html',
  styleUrls: ['./cms-products-and-services.component.scss'],
  providers: [FieldsService]
})
export class CmsProductsAndServicesComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = false;

  cmsProductsServices: any =  [
    {
      titleSrc: '/uploads/images/recargas/condiciones-de-servicio.png',
      titleTitle: 'texto de item',
      titleAlt: 'titleAlt',
      sendUrlCalc: '#',
      sendUrlStatus: '#',
      Fields: [
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 1',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 2',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 3',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 4',
          linkIcon: null
        },
      ]
    }
  ];

  captchaValid = false;
  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;
  @ViewChild ('appFormTurnStatus', {static: false}) formdTurnStatus: DynamicFormContainerComponent;
  @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef;

  data: Array<{formCostTurn: any, formTurnStatus}> = [];

  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }

  constructor(
    service: FieldsService,
    private apiService: ComponentService,
    ){
    this.data['formCostTurn'] = service.getQuestions(service.getCostTurn());
    this.data['formTurnStatus']= service.getQuestions(service.formTurnStatus());
  }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0) {
      this.cmsProductsServices = this.contenido.Contenido;
      this.addRecaptchaScript();
    }

  }


  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    if(this.recaptchaElement){
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : '6LefLd0UAAAAAMKXEXDyCxitCvKT1LWkX01gC99E',
        'callback': (response) => {
          this.captchaValid = true;
        }
      });
    }
  }

  onSubmitCostTurns() {
    this.formCostTurn.onSubmit();
    const datos: any = this.formCostTurn.payLoad;
    if(this.formCostTurn.form.invalid){
      return false;
    }
    this.modal.show(
      'assets/images/icon-modal-send.png',
      'assets/images/title-modal-send.png',
      'Espere mientras consultamos la información'
    );
    this.apiService.getCalcularGiroNacional(datos.valorGiro).subscribe(
      (result: any) => {
        let total = result.tarifa.tarifaFija + result.tarifa.tarifaVariable;
        total -= result.tarifa.descuento;
        this.formCostTurn.form.controls.costo.setValue(
          formatNumber(total, 'en'),
          {emitEvent:false}
        );
        this.formCostTurn.form.controls.total.setValue(
          formatNumber(
            result.tarifa.valorTotalTransaccion, 'en'),
            {emitEvent:false}
          );
        this.modal.hidde();
      },
      err => {
        this.modal.show(
          'assets/images/icon-modal-error.png',
          'assets/images/title-modal-error.png',
          err.message
        );
      }
    );
  }

  onSubmitTurnStatus() {

    if(this.formdTurnStatus.form.invalid || !this.captchaValid){
      return false;
    }
    this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Espere mientras consultamos la información');
    this.formdTurnStatus.onSubmit();
    const datos: any = this.formdTurnStatus.payLoad;

    if(datos.valor == '' || datos.digito == '' || !this.captchaValid){
      this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Debe ingresar un valor');
      return false;
    }

    this.apiService.getValidarGiroNacional(datos.valor, datos.digito).subscribe(
      (result: any) => {
        if(result){
          if(result.Mensaje == '?'){
            this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png',  result.FCT.Estado);
          }else{
            this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png',  result.Mensaje);
          }
        }else{
          this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', '');
        }
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err);
      }
      );
  }

  onChangeValues(evt){
    this.formCostTurn.form.controls.costo.setValue(formatNumber(0, 'en'), {emitEvent:false});
    this.formCostTurn.form.controls.total.setValue(formatNumber(0, 'en'), {emitEvent:false});
  }

}
