import { FieldsService } from 'src/app/_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from '../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';

import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from 'src/app/_services/templates.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-calculate-gyre',
  templateUrl: './calculate-gyre.component.html',
  styleUrls: ['./calculate-gyre.component.scss'],
  providers: [FieldsService]
})
export class CalculateGyreComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = false;

  calculateGyre: any = {
    Nombre: 'Calculadora',
    Ubicacion: '21',
    Contenido: [
      {
        bigImageUrl: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        bigImageAlt: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        bigImageTitle: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        titleImageUrl: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        titleImageAlt: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        titleImageTitle: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        description: "Ingresa el valor a enviar y te diremos cuál será el total a pagar por realizar el giro.",
        botonText: "Ver tabla de tarifas de envío",
        botonUrl: "#",
      }
    ]
  }

  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;

  data: Array<{formCostTurn: any, formTurnStatus}> = [];
  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }
  constructor(
     service: FieldsService,
     private apiService: ComponentService
    ) {
    this.data['formCostTurn'] = service.getQuestions(service.getCostTurn())
   }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.calculateGyre = this.contenido;
    }
  }

  onSubmitCostTurns(){
    if(this.formCostTurn.form.invalid){
      return false;
    }
    this.modal.show( 'assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Espere mientras consultamos la información');
    this.formCostTurn.onSubmit();
    const datos:any = this.formCostTurn.payLoad;
    this.apiService.getCalcularGiroNacional(datos.valorGiro).subscribe(
      (result: any) => {
        let total = result.tarifa.tarifaFija + result.tarifa.tarifaVariable;
        total -= result.tarifa.descuento;
        this.formCostTurn.form.controls.costo.setValue(formatNumber(total, 'en'), {emitEvent:false});
        this.formCostTurn.form.controls.total.setValue(formatNumber(result.tarifa.valorTotalTransaccion, 'en'), {emitEvent:false});
        this.modal.hidde();
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err);
      }
    );
  }

  onChangeValues(evt){
    this.formCostTurn.form.controls.costo.setValue(formatNumber(0, 'en'), {emitEvent:false});
    this.formCostTurn.form.controls.total.setValue(formatNumber(0, 'en'), {emitEvent:false});
  }

}
