import { API_URI } from 'src/app/_services/apiUrl';
import { ComponentBase } from './../../_models/component-base';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner-main',
  templateUrl: './banner-main.component.html',
  styleUrls: ['./banner-main.component.scss']
})
export class BannerMainComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  data: any = {};

  banner = 0;

  backOffice ={
      titulo_img : './assets/images/banner_home/title_banner_1.png',
      titulo_sub : '',
      text_descriptivo: 'Debes tener una cuenta bancaria habilitada para pagos por internet.',
      url_btn_1 : 'De lo contrario',
      url_btn_2 : 'De lo contrario',
      background_img : './assets/images/banner_home/imagenslidehomenuevo@2x.png',
      class_img: 'full_width'
    };

  constructor(){
  }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0){
      this.data = this.contenido.Contenido[0];
    } else {
      this.data = this.backOffice;
    }
  }
}
