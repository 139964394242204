import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-three-column-gyre',
  templateUrl: './three-column-gyre.component.html',
  styleUrls: ['./three-column-gyre.component.scss']
})
export class ThreeColumnGyreComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  data: any = [
    {
      imageUrlMovil: 'uploads/images/slider-3-title-movil.png',
      imageUrlDesktop: 'uploads/images/slider-3-title.png',
      imageAlt: 'Alt de imagen',
      imageTitle: 'Title de imagen',
      Fields: [
      {
        title: 'Sitio web',
        imageUrl: '/uploads/images/Grupo 2074.png',
        imageAlt: '/uploads/images/Grupo 2074.png',
        imageTitle: '/uploads/images/Grupo 2074.png',
        link: 'web',
        button: 'Inicia sesión o regístrate'
      },
      {
        title: 'Aplicación',
        imageUrl: '/uploads/images/Grupo 2062.png',
        imageAlt: '/uploads/images/Grupo 2062.png',
        imageTitle: '/uploads/images/Grupo 2062.png',
        link: 'app',
        button: 'Descarga el app'
      },
      {
        title: 'Facebook',
        imageUrl: '/uploads/images/Grupo 2074.png',
        imageAlt: '/uploads/images/Grupo 2074.png',
        imageTitle: '/uploads/images/Grupo 2074.png',
        link: 'face',
        button: 'Conectar mi cuenta'
      }
    ]
  }];

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.data = this.contenido.Contenido;
    }
  }
}
