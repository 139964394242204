import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-horizontal-action',
  templateUrl: './horizontal-action.component.html',
  styleUrls: ['./horizontal-action.component.scss']
})
export class HorizontalActionComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  data: any = {
    Nombre: 'Consulta de giros',
    Ubicacion: '22',
    Contenido: [
      {
        title: '¿Necesitas realizar un giro por internet?',
        leftUrl: '#',
        leftTitle: 'Regístrate',
        rightUrl: '#',
        rightTitle: 'Inicia sesión',
      }
    ]
  }

  constructor(
  ) {
  }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.data = this.contenido;
    }
  }

}
