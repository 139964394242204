import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  pricing = {
    Nombre: 'Tarifas',
    Ubicacion: '17',
    Contenido: {
      image: API_URI+'uploads/images/tarifas.png'
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
