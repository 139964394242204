import { Component, Input, OnInit } from '@angular/core';
import { FormGroup }                 from '@angular/forms';
import { BannerBase }              from '../../class/banner-base';
// import { FieldsControlService }    from '../../service/fields-control.service';

@Component({
  selector: 'app-dynamic-banner',
  templateUrl: './dynamic-banner.component.html',
  styleUrls: ['./dynamic-banner.component.scss']
})
export class DynamicBannerComponent implements OnInit {
  @Input() banner: BannerBase<any>;

  constructor() { }

  ngOnInit() {
  }

}
