import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebLayoutComponent } from './layouts/web-layout/web-layout.component';
import { ComponentsModule } from './components/components.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TAGID} from './_services/apiUrl';
import { OnlynumbersDirective } from './_helpers/onlynumbers.directive';

@NgModule({
  declarations: [AppComponent, WebLayoutComponent, ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    AngularFontAwesomeModule,
    HttpClientModule
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: TAGID},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
