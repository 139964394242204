import { Component, OnInit, ViewChild  } from '@angular/core';

import { FieldsService } from '../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from '../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
@Component({
  selector: 'app-form-dynamic',
  templateUrl: './form-dynamic.component.html',
  styleUrls: ['./form-dynamic.component.scss'],
  providers:  [FieldsService]

})
export class FormDynamicComponent implements OnInit {
  @ViewChild('appFormContainer', {static: false}) childOne:DynamicFormContainerComponent;

  questions: any[];

  data;

  constructor(service: FieldsService) {
    this.questions = service.getQuestions(service.getHttpConfigFormDynamic());
  }

  ngOnInit() {
  }

  onSubmit(){
    this.childOne.onSubmit();
  }

}
