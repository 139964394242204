import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-profitability',
  templateUrl: './profitability.component.html',
  styleUrls: ['./profitability.component.scss']
})
export class ProfitabilityComponent implements OnInit {


  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  profitability: any  =
    {
      Nombre: 'Contenido de imagen 2',
      Ubicacion: '8',
      Contenido: [
        {
          bigImageUrl: 'uploads/images/profitability-image.png',
          bigImageAlt: 'Rentabilidad',
          bigImageTitle: 'Rentabilidad',
          Fields : [
            {
              Title: "<p class='f-28'>Quiero un <strong class='f-xs-28 f-md-36'>Efecty</strong><br>en <strong class='f-xs-28 f-md-36'>mi negocio </strong></p>",
              ImageUrl: 'uploads/images/Grupo -65.png',
              altImage: 'Quiero un Efecty en mi negocio',
              subtitle: 'Tengo un negocio',
              buttonText: 'Vincula tu comercio',
              buttonLink: '#vc',
            },
            {
              Title: "<p class='f-28'>Quiero un <strong class='f-xs-28 f-md-36'>Efecty</strong><br>en <strong class='f-xs-28 f-md-36'>mi negocio </strong></p>",
              ImageUrl: 'uploads/images/Grupo -65.png',
              altImage: 'Quiero un Efecty en mi negocio',
              subtitle: 'Tengo un negocio',
              buttonText: 'Vincula tu comercio',
              buttonLink: '#vc',
            },
            {
              Title: "<p class='f-28'>Quiero un <strong class='f-xs-28 f-md-36'>Efecty</strong><br>en <strong class='f-xs-28 f-md-36'>mi negocio </strong></p>",
              ImageUrl: 'uploads/images/Grupo -65.png',
              altImage: 'Quiero un Efecty en mi negocio',
              subtitle: 'Tengo un negocio',
              buttonText: 'Vincula tu comercio',
              buttonLink: '#vc',
            }
          ]
        }
      ]
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.profitability = this.contenido;
    }
    console.log(this.profitability.contenido);
  }
}
