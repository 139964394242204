import { Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FieldsBase } from '../../class/fields-base';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() question: FieldsBase<any>;
  @Input() form: FormGroup;

  error = '';

  get isValid() {
    return this.form.controls[this.question.key].disabled ?
      true :
      this.form.controls[this.question.key].valid }

  get isInvalid() {
    if (!this.form.controls[this.question.key].touched){
      return false;
    }
    this.error = '';
    const errores = this.form.controls[this.question.key].errors;
    if(errores){
      for (let key of Object.keys(errores)) {
        this.error += this.question.errormessages[key] || 'Valor Incorrecto' ;
      }
    }
    return this.form.controls[this.question.key].invalid;
  }

  constructor() { }

  ngOnInit() {
  }
}
