import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
let API_URI;
@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {

  constructor(
    private http: HttpClient,
  ) {
    this.getJSON();
   }

  async getJSON() {
    const data: any = await this.http.get('./assets/endpoint.json').toPromise();
    API_URI = data.enpoint_url;
  }

  /**
   * Get template content
   * @method GET
   */
  getBase(): Observable<object> {
    return this.http.get(`${API_URI}api/PageContent/Base`);
  }

  /**
   * Get template content
   * @method GET
   */
  getContent(params: string): Observable<object> {
    return this.http.get(`${API_URI}api/PageContent/GetPagesContent?name=${params}`);
  }
  /**
   * Get Blog content
   * @method GET
   */
  getBlog(): Observable<object> {
    return this.http.get(`${API_URI}api/Blogs`);
  }

  getApiUri(){
    return API_URI;
  }
}
