import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-with-us-our-values',
  templateUrl: './work-with-us-our-values.component.html',
  styleUrls: ['./work-with-us-our-values.component.scss']
})
export class WorkWithUsOurValuesComponent implements OnInit {

  workWithUsOurValues =
    {
      Nombre: 'Beneficios y formación',
      Ubicacion: '10',
      Contenido: [
        {
          Propiedad: 'header',
          Contenido: {
            icon: API_URI+'uploads/images/Grupo 1960.png',
            imageTitle: API_URI+'/uploadsdsimages/nuestros-valores.png',
            altImage: 'Nuestros Valores'
          }
        },
        {
          Propiedad: 'list',
          Contenido: [
            {
              Propiedad: 'item',
              Contenido: [
                {
                  item: 'Servicio'
                },
                {
                  item: 'Respeto'
                },
                {
                  item: 'Responsabilidad'
                },
                {
                  item: 'Confianza'
                }
              ]
            },
            {
              Propiedad: 'item',
              Contenido: [
                {
                  item: 'Satisfacemos a nuestros clientes'
                },
                {
                  item: 'Gestionamos el cambio'
                },
                {
                  item: 'Nos apasionan los resultados'
                },
                {
                  item: 'Trabajamos en equipo'
                },
                {
                  item: 'Somos dinamicos'
                }
              ]
            }
          ]
        }
      ]
    }

  constructor() { }

  ngOnInit() {
  }

}
