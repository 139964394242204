import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.scss']
})
export class PromoCardComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  promoCard: any = {
    Nombre: 'Card promociones',
    Ubicacion: '16',
    Contenido: [
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
      {
        image: 'uploads/images/por defecto -1.png',
        alt: 'Giromanía Efecty',
        title: '<p><strong>Giromanía Efecty, "te premiamos..."</strong></p>',
        buttonText: 'Ver términos y condiciones',
        buttonLink: '#',
      },
    ]
  }

  constructor() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.promoCard = this.contenido.Contenido;
    }
   }

  ngOnInit() {
  }

}
