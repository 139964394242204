import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-internal-portals',
  templateUrl: './internal-portals.component.html',
  styleUrls: ['./internal-portals.component.scss']
})
export class InternalPortalsComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  private data: any = {};

  api_url = API_URI;

  internalPortals :any =
    {
      Nombre: 'Portales Internos',
      Ubicacion: '1',
      Contenido: [
                {
                  link: '/sieweb-live',
                  icon: API_URI+'uploads/images/Grupo 2265.png',
                  title: 'Sieweb Live'
                },
                {
                  link: '/success-factors',
                  icon: API_URI+'uploads/images/Grupo 1990.png',
                  title: 'Succes Factors'
                },
                {
                  link: '/acceso-email-corporativo',
                  icon: API_URI+'uploads/images/Grupo 1986.png',
                  title: 'Acceso email corporativo'
                },
                {
                  link: '/acceso-email-puntos-de-atencion',
                  icon: API_URI+'uploads/images/Grupo 2001.png',
                  title: 'Acceso email puntos de atención'
                },
                {
                  link: '/asistencia-remota',
                  icon: API_URI+'uploads/images/Grupo 2006.png',
                  title: 'Asistencia remota'
                },
                {
                  link: '/enrol',
                  icon: API_URI+'uploads/images/Grupo 2012.png',
                  title: 'Enrol'
                },
                {
                  link: '/oficina-virtual-efecty',
                  icon: API_URI+'uploads/images/Grupo 2016.png',
                  title: 'Oficina Virtual Efecty'
                },
                {
                  link: '/radica-tu-pqr-puntos-de-atencion',
                  icon: API_URI+'uploads/images/Grupo 2028.png',
                  title: 'Radica tu PQR. Puntos de atención'
                },
                {
                  link: '/consulta-tu-pqr-puntos-de-atencion',
                  icon: API_URI+'uploads/images/Grupo 2017.png',
                  title: 'Consulta tu PQR. Puntos de atención'
                }
              ]
    }

  constructor() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.internalPortals = this.contenido.Contenido;
    }
  }

  ngOnInit() {
  }

}
