import { FieldsService } from 'src/app/_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from '../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';

import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from 'src/app/_services/templates.service';

@Component({
  selector: 'app-international-calculate-gyre',
  templateUrl: './international-calculate-gyre.component.html',
  styleUrls: ['./international-calculate-gyre.component.scss'],
  providers: [FieldsService]
})
export class InternationalCalculateGyreComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = false;
  api_url = API_URI;

  internationalCalculateGyre: any = {
    Nombre: 'Calculadora',
    Ubicacion: '21',
    Contenido: [
      {
        bigImageUrl: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        bigImageAlt: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        bigImageTitle: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-facilidad.png',
        titleImageUrl: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        titleImageAlt: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        titleImageTitle: API_URI+'uploads/images/calcula-el-costo-de-tu-giro-titulo.png',
        description: "Ingresa el valor a enviar y te diremos cuál será el total a pagar por realizar el giro.",
        botonText: "Ver tabla de tarifas de envío",
        botonUrl: "#",
      }
    ]
  }

  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;

  data: Array<{formCostTurn: any, formTurnStatus}> = [];

  constructor(
     service: FieldsService,
     //private apiService: ComponentService
    ) {
    this.data['formCostTurn'] = service.getQuestions(service.getInternationalGyreForm())
   }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.internationalCalculateGyre = this.contenido;
    }
  }

  onSubmitCostTurns(){
    this.formCostTurn.onSubmit()

  }

}
