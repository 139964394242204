import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.scss']
})
export class SingleBlogComponent implements OnInit {

  @Input() blog: any;
  api_url = API_URI;
  youtube = '';
  
  social =  [
    {
      icon: "<i class='fab fa-whatsapp social-icon'></i>",
      link: 'whatsapp'
    },
    {
      icon: '<i class="fab fa-facebook social-icon"></i>',
      link: 'facebook'
    },
    {
      icon: '<i class="fab fa-twitter social-icon"></i>',
      link: 'twiitter'
    },
    {
      icon: '<i class="fab fa-linkedin social-icon"></i>',
      link: 'linkedin'
    }
  ];

  constructor(
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.blog.etiquetas = this.blog.etiquetas.split(',');
  }

  getCurrentUrl(){
    return 'https://www.efecty.com.co/blog/'+this.blog.shortname;
  }
  
  share(network){
    if(network == 'facebook'){
      this.facebook();  
    }
    if(network == 'linkedin'){
      this.linkedin();  
    }
    if(network == 'whatsapp'){
      this.whatsapp();  
    }
    if(network == 'twiitter'){
      this.twitter();  
    }
  }
  facebook(){
    let face = window.open(
      'https://www.facebook.com/sharer/sharer.php?u='+this.getCurrentUrl(),
      'facebook-popup',
      'height=350,width=600'
    );
  }
  
  linkedin(){
    let face = window.open(
      'https://www.linkedin.com/shareArticle?mini=true&url='+this.getCurrentUrl()+'&title=&summary=&source=',
      'linkedin-popup',
      'height=350,width=600'
    );
  }

  twitter(){
    let face = window.open(
      'https://twitter.com/intent/tweet?text='+this.getCurrentUrl(),
      'twitter-popup',
      'height=350,width=600'
    );
  }
  
  whatsapp(){
    let face = window.open(
      'https://web.whatsapp.com/send?text='+this.getCurrentUrl(),
      'whatsapp-popup',
      'height=350,width=600'
    );
  }

  secureUrl(code){
    const base = "https://www.youtube.com/embed/";
    const youtube = this._sanitizer.bypassSecurityTrustResourceUrl(base+code);
    return youtube;
  }

}
