import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { subscribeOn } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss']
})
export class ArticleCardComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url: any;
  activeModal = true;
  currentBlog;
  articleCard: any= []


  social =  [
    {
      icon: "<i class='fab fa-whatsapp social-icon'></i>",
      link: 'whatsapp'
    },
    {
      icon: '<i class="fab fa-facebook social-icon"></i>',
      link: 'facebook'
    },
    {
      icon: '<i class="fab fa-twitter social-icon"></i>',
      link: 'twiitter'
    },
    {
      icon: '<i class="fab fa-linkedin social-icon"></i>',
      link: 'linkedin'
    }
  ];


  constructor(
    private apiService: TemplateServiceService,
    private route: Router,

  ) { }

  ngOnInit() {
    this.apiService.getBlog()
      .subscribe(
        (data) => {
          this.articleCard = data;
          this.validateCurrentBlog();
        },
        error => {
        }

      )
  }

  validateCurrentBlog(){
    const currentPage = this.route.url;
    const urlparts = currentPage.split('/');
    if (urlparts[2]) {
      this.searchBlog(urlparts[2]);
    }
  }

  searchBlog(id) {
    this.setCurrent(this.articleCard.find(articulo => articulo.shortname == id));
  }

  setCurrent(blog) {
    const uno  = this.articleCard[ Math.floor(Math.random() * this.articleCard.length) ];
    const dos  = this.articleCard[ Math.floor(Math.random() * this.articleCard.length) ];
    blog['otros'] = [uno, dos];
    this.currentBlog = blog;
  }

  getCurrentUrl(blog){
    return 'https://www.efecty.com.co/blog/'+ blog.shortname;
  }

  share(network , blog){
    if(network == 'facebook'){
      this.facebook(blog);
    }
    if(network == 'linkedin'){
      this.linkedin(blog);
    }
    if(network == 'whatsapp'){
      this.whatsapp(blog);
    }
    if(network == 'twiitter'){
      this.twitter(blog);
    }
  }
  facebook(blog){
    let face = window.open(
      'https://www.facebook.com/sharer/sharer.php?u='+this.getCurrentUrl(blog),
      'facebook-popup',
      'height=350,width=600'
    );
  }

  linkedin(blog){
    let face = window.open(
      'https://www.linkedin.com/shareArticle?mini=true&url='+this.getCurrentUrl(blog)+'&title=&summary=&source=',
      'linkedin-popup',
      'height=350,width=600'
    );
  }

  twitter(blog){
    let face = window.open(
      'https://twitter.com/intent/tweet?text='+this.getCurrentUrl(blog),
      'twitter-popup',
      'height=350,width=600'
    );
  }

  whatsapp(blog){
    let face = window.open(
      'https://web.whatsapp.com/send?text='+this.getCurrentUrl(blog),
      'whatsapp-popup',
      'height=350,width=600'
    );
  }


}
