import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-slider-find-us-icons',
  templateUrl: './slider-find-us-icons.component.html',
  styleUrls: ['./slider-find-us-icons.component.scss']
})
export class SliderFindUsIconsComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;

  sliderFindUsIcons: any = {
    Nombre: 'Slider iconos',
    Ubicacion: '20',
    Contenido: [
      {
        iconSrc: API_URI+'uploads/images/Grupo 1712.png',
        iconAlt: API_URI+'uploads/images/Grupo 1712.png',
        iconTitle: API_URI+'uploads/images/Grupo 1712.png',
        title: "<p><strong>Encuéntranos</strong><br>también en:</p>",
        Fields: [
          {
            img: API_URI+'/uploads/images/dimonex.png',
            title: 'Dimonex',
            link: 'dimonex',
          },
          {
            img: API_URI+'/uploads/images/oxxo.png',
            title: 'Oxxo',
            link: 'oxxo',
          },
          {
            img: API_URI+'/uploads/images/jumbo.png',
            title: 'Jumbo',
            link: 'jumbo',
          },
          {
            img: API_URI+'/uploads/images/metro.png',
            title: 'Metro',
            link: 'metro',
          },
          {
            img: API_URI+'/uploads/images/olimpica.png',
            title: 'Olimpica',
            link: 'olimpica',
          },
          {
            img: API_URI+'/uploads/images/flamingo.png',
            title: 'Flamingo',
            link: 'flamingo',
          },
          {
            img: API_URI+'/uploads/images/dimonex.png',
            title: 'Dimonex',
            link: 'dimonex',
          },
        ]
      }
    ]
  }


  slideConfig = {
    "slidesToShow": 6,
    'slidesToScroll': 1,
    'arrows': true,
    'centerMode': true,
    "autoplay": true,
    'centerPadding': '20px',
    "responsive": [
      {
        'breakpoint': 1408,
        'settings': {
          "slidesToShow": 6,
          'centerMode': true,
          'centerPadding': '10px',
        }
      },
      {
        'breakpoint': 1216,
        'settings': {
          "slidesToShow": 4,
          'centerMode': false
        }
      },
      {
        'breakpoint': 1024,
        'settings': {
          "slidesToShow": 3,
          'centerMode': false
        }
      },
      {
        'breakpoint': 768,
        'settings': {
          "slidesToShow": 2,
          'centerMode': false
        }
      },
      {
        'breakpoint': 480,
        'settings': {
          "slidesToShow": 2,
          'centerMode': false
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.sliderFindUsIcons = this.contenido;
    }
  }

  slickInit($event){ }
  breakpoint($event){ }
  afterChange($event){ }
  beforeChange($event){ }
}
