import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(
    private titulopagina: Title,
    private metatags: Meta
  ) { }

  ngOnInit() {
    this.titulopagina.setTitle('Error - Página no encontrada | Efecty');
    this.metatags.updateTag({ name: 'description', content: 'La página que estás buscando no está disponible' });
    this.metatags.updateTag({ name: 'keywords', content: '' });
  }

}
