import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-about-us-our-purpose',
  templateUrl: './about-us-our-purpose.component.html',
  styleUrls: ['./about-us-our-purpose.component.scss']
})
export class AboutUsOurPurposeComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url: any;
  activeModal = true;

  aboutUsOurPurpose :any = {
    Nombre: 'Nuestro propósito',
    Ubicacion: '11',
    Contenido: [
      {
        iconSrc: 'uploads/images/about-us/our-purpose-footer-title.png',
        iconAlt: '',
        iconTitle: '',
        title: '<p>❝facilitar la vida generando experiencias de <strong>confianza, cercanía y tranquilidad❞.</strong></p>',
        Fields: [
          {
            icon: 'uploads/images/about-us/our-purpose-icon-1.png',
            title: 'Nuestra Misión',
            description: "❝Brindamos soluciones en transacciones y medios de pago que contribuyen al bienestar y desarrollo de nuestros grupos de interés con innovación y excelencia❞."
          },
          {
            icon: 'uploads/images/Grupo 1857.png',
            title: 'Nuestra Visión',
            description: "❝Ser la empresa omnicanal número uno en medios de pago en Latinoamérica❞."
          }
        ]
      },
    ]
  }

  constructor() { }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.aboutUsOurPurpose = this.contenido;
    }

  }

}
