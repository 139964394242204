import { Component, OnInit, Input } from '@angular/core';
import { BannerBase } from '../../_helpers/dynamic_banner/class/banner-base';

@Component({
  selector: 'app-work-with-us-we-look-for-talent',
  templateUrl: './work-with-us-we-look-for-talent.component.html',
  styleUrls: ['./work-with-us-we-look-for-talent.component.scss'],
  // providers:  [BannerService]

})
export class WorkWithUsWeLookForTalentComponent implements OnInit {
  @Input() data: BannerBase<any>;

  constructor(){}

  ngOnInit() {
  }

}
