import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collections-facilitate-payments',
  templateUrl: './collections-facilitate-payments.component.html',
  styleUrls: ['./collections-facilitate-payments.component.scss']
})
export class CollectionsFacilitatePaymentsComponent implements OnInit {
  API_URI = API_URI;
  constructor() { }

  ngOnInit() {
  }

}
