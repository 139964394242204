import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';


@Component({
  selector: 'app-form-cost-turn',
  templateUrl: './form-cost-turn.component.html',
  styleUrls: ['./form-cost-turn.component.scss']
})

export class FormCostTurnComponent implements OnInit {
  costTurnForm: FormGroup;
  submitted = false;
  activeModal = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.costTurnForm = this.formBuilder.group({
      valor: ['', [Validators.required]]
    });
  }
  onSubmitCostTurns() {
      this.submitted = true;

      if (this.costTurnForm.invalid) {
          return;
      }
  }

  get f() {
    return this.costTurnForm.controls;
  }
}
