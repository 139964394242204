import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-information-mtcn',
  templateUrl: './information-mtcn.component.html',
  styleUrls: ['./information-mtcn.component.scss']
})
export class InformationMtcnComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  private data: any = {};

  informationMTCN: any =
    {
      Nombre: 'Información MTCN',
      Ubicacion: '6',
      Contenido:
      {
        imageUrl: 'uploads/images/Grupo 2075.png',
        imageTitle: 'Información MTCN',
        imageAlt: 'Información MTCN',
        description: 'Una vez se ha enviado el giro internacional, el cajero le informará el nemero de MTCN <strong>(Money Transfer Control Number)</strong> código de 10 dígitos, el cual debe comunicarse a su Destinatario. El valor del Giro Internacional únicamente se recibe en pesos colombianos en los Puntos de Atención al Público habilitados.',
      }
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.informationMTCN = this.contenido;
    }
  }

}
