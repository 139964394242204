import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentService } from 'src/app/_services/templates.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {


  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(){
      this.active = true;
    }
  };

  resultados:any = null;
  constructor(
    private route: ActivatedRoute,
    private serviceComponent: ComponentService
  ) { }

  ngOnInit() {
    this.route.url.subscribe(
      values => {
        if(values[1]){
          this.buscarTerminos(values[1].path);
        }
      }
    )
  }

  buscarTerminos(terminos){
    this.loading.show();
    this.resultados = null;
    this.serviceComponent.getBusqueda(terminos).subscribe(
      result => {
        this.resultados = result;
        this.loading.hidde();
      },
      error => {
        this.loading.hidde();
        this.resultados = [];
      }
    );
  }


}
