import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-work-with-us-performance',
  templateUrl: './work-with-us-performance.component.html',
  styleUrls: ['./work-with-us-performance.component.scss']
})
export class WorkWithUsPerformanceComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  workWithUsPerformance: any = {
    Nombre: 'Desempeño',
    Ubicacion: '18',
    Contenido: [
      {
          title: '<h5>Reconocemos<strong> la excelencia</strong> a tráves del <strong>sistema de gestión del desempeño</strong></h5>',
          description: 'Este modelo nos permite una medición de la gestión del colaborador y sus resultados en relación con las metas propuestas a través de:',
          image: 'uploads/images/plan-de-reconocimientos.png',
          alt: 'Plan de reconocimientos',
          icon1: 'uploads/images/Grupo 1827.png',
          iconTitle1: '<h4>Objetivos de<br> desempeño<br> individual</h4>',
          icon2: 'uploads/images/Grupo 1830.png',
          iconTitle2: '<h4>Evaluación de<br> competencias<br> 360°</h4>'
      }
    ]
  }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.workWithUsPerformance = this.contenido;
    }
  }

}
