import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';

@Component({
  selector: 'app-form-advisory',
  templateUrl: './form-advisory.component.html',
  styleUrls: ['./form-advisory.component.scss']
})
export class FormAdvisoryComponent implements OnInit {

  formAdvisory = {
    Nombre: 'Formulario para asesoría',
    Ubicacion: '24',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          title: 'Déjanos tus datos para asesorarte, diligencia el formulario y pronto te contactáremos!',
        }
      },
      {
        Propiedad: 'form',
        Contenido: {
          action: 'form',
          Contenido: [
            {
              Propiedad: 'input',
              label: 'Razón Social/Nombre',
              type: 'text'
            },
            {
              Propiedad: 'select',
              label: 'Tipo de documento:',
              Contenido: [
                {
                  option: 'Cedula de Ciudania',
                  value: 'cc'
                },
                {
                  option: 'Cedula de Extrangeria',
                  value: 'ce'
                },
                {
                  option: 'Pasaporte',
                  value: 'pp'
                },
                {
                  option: 'NIT',
                  value: 'nit'
                }
              ]
            },
            {
              Propiedad: 'input',
              label: 'Número de documento',
              placeholder: 'sin puntos ni comas',
              type: 'number'
            },
            {
              Propiedad: 'input',
              label: 'Digito de verificación',
              type: 'number'
            },
            {
              Propiedad: 'select',
              label: 'Municipio',
              Contenido: [
                {
                  option: 'Municipio 1'
                },
                {
                  option: 'Municipio 2'
                }
              ]
            },
            {
              Propiedad: 'input',
              label: 'Dirección',
              type: 'text'
            },
            {
              Propiedad: 'input',
              label: 'Número de teléfono',
              type: 'number'
            },
            {
              Propiedad: 'input',
              label: 'Número de celular',
              type: 'number'
            },
            {
              Propiedad: 'input',
              label: 'Correo electrónico',
              type: 'email'
            },
            {
              Propiedad: 'input',
              label: 'Nombre de contacto',
              type: 'text'
            },
            {
              Propiedad: 'input',
              label: 'Apellido de contacto',
              type: 'text'
            },
            {
              Propiedad: 'select',
              label: 'tipo de documento',
              type: 'text'
            },
            {
              Propiedad: 'input',
              label: 'Número de documento',
              placeholder: 'sin puntos ni comas',
              type: 'number'
            },
            {
              Propiedad: 'input',
              label: 'Cargo del contacto',
              type: 'text'
            }
          ],
          terms: 'Autoriza tratamiento de datos personales',
          policyText: 'Conoce la política de datos aquí',
          policyLink: 'http://ec2-13-52-138-131.us-west-1.compute.amazonaws.com/uploads/Politica-de-Tratamiento-de-Datos-Personales-1.pdff',
          button: 'Enviar mis datos'
        }
      }
    ]
  }

  pageForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService
  ) { }

  ngOnInit() {
    this.pageForm = this.formBuilder.group({
      cantidad: ['', Validators.required],
      tipo: ['', Validators.required],
      razonsocial: ['', Validators.required],
      nombre: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      tipodocumento: ['', Validators.required],
      numerodocumento: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
      digitoverficacion: ['', Validators.required],
      tiempoconstitucion: ['', Validators.required],
      ventasmensuales: ['', Validators.required],
      direccion: ['', Validators.required],
      municipio: ['', Validators.required],
      telefono: ['', Validators.required],
      contactonombre: ['',
        [Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/)]
      ],
      contactoapellido: ['',
        [Validators.required,
        Validators.pattern(/^[a-zA-Z\s]*$/)]
      ],
      contactocargo: ['',Validators.required],
      contactotelefono: ['',Validators.required],
      contactocorreo: ['',[Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      contactotipodocumento: ['',Validators.required],
      contactonumerodocumento: ['',[Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      mensaje: ['',Validators.required],

    })
  }

  submit() {
    if(this.pageForm.invalid){
      return false;
    }
    this.apiService.postAsesoria(this.pageForm.value)
      .subscribe(
        response => {

        },
        err => {

        }
      )

  }


}
