import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informative-content-type-one',
  templateUrl: './informative-content-type-one.component.html',
  styleUrls: ['./informative-content-type-one.component.scss']
})
export class InformativeContentTypeOneComponent implements OnInit {
  API_URI = API_URI;
  constructor() { }

  ngOnInit() {
  }

}
