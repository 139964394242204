import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from 'src/app/_services/templates.service';

@Component({
  selector: 'app-international-check-gyre',
  templateUrl: './international-check-gyre.component.html',
  styleUrls: ['./international-check-gyre.component.scss']
})
export class InternationalCheckGyreComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  activeModal = true;
  api_url = API_URI;

  InternationalcheckGyres: any = {
    Nombre: 'Consulta de giros',
    Ubicacion: '22',
    Contenido: [
      {
        bigImageUrl: 'uploads/images/consulta-tus-giros.png',
        bigImageAlt: 'uploads/images/consulta-tus-giros.png',
        bigImageTitle: 'uploads/images/consulta-tus-giros.png',
        titleImageUrl: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        titleImageAlt: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        titleImageTitle: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        imageTitleMobile: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        description: 'Confirma si tu giro ya fue reclamado o esta pendiente.'
      }
    ]
  }
  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;
  @ViewChild ('appFormTurnStatus', {static: false}) formdTurnStatus: DynamicFormContainerComponent;
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  data: Array<{formCostTurn: any, formTurnStatus}> = [];

  constructor(
    service: FieldsService,
    private apiService: ComponentService,
  ) {
    this.data['formCostTurn'] = service.getQuestions(service.getInternationalCheckGyreForm())
  }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.InternationalcheckGyres = this.contenido;
    }
    this.addRecaptchaScript();
  }
  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }
  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LefLd0UAAAAAMKXEXDyCxitCvKT1LWkX01gC99E',
      'callback': (response) => {
      }
    });
  }

  onSubmitCostTurns(){
    this.formCostTurn.onSubmit()
    this.apiService.getCalcularGiroNacional(this.formCostTurn.payLoad);

  }

  onSubmitTurnStatus(){
    this.formdTurnStatus.onSubmit()
    this.apiService.getCalcularGiroNacional(this.formdTurnStatus.payLoad);

  }

}
