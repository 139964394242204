import { Injectable } from '@angular/core';
import { TemplateBase } from '../_models/template-base';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

let API_URI;
@Injectable()
export class ComponentService {
  public data: TemplateBase<any>;
  constructor(
    public http: HttpClient // private router: Router,
  ) {
    this.getJSON();
  }

  getJsonToComponents(data) {
    this.data = new TemplateBase(data[0]);
  }

  async getJSON() {
    const data:any = await this.http.get('./assets/endpoint.json').toPromise();
    API_URI = data.enpoint_url;
  }
  /**
   * Get template content
   * @method GET
   */
  getTemplateContent(params: string): Observable<object> {
    return this.http.get(`${API_URI}api/PageContent/GetPagesContent?name=${params}`);
  }

  postAsesoria(form){
    return this.http.post(`${API_URI}api/Asesorias/`,form);
  }

  postQuiero(form){
    return this.http.post(`${API_URI}api/Quiero/`,form);
  }

  getCalcularGiroNacional(valor){
    return this.http.get(`${API_URI}api/MoneyOrders/GetMoneyOrders?valor=${valor}`);
  }

  getValidarGiroNacional(numero, digito){
    return this.http.get(`${API_URI}api/MoneyOrderStatus/Status?numero=${numero}&digito=${digito}`);
  }

  getCalcularGiroInternacional(valor){
    return this.http.get(`${API_URI}api/MoneyOrders/GetMoneyOrders?valor=${valor}`);
  }

  getValidarGiroInternacional(valor){
    return this.http.get(`${API_URI}api/MoneyOrders/GetMoneyOrders?valor=${valor}`);
  }

  postConsultaPQRNatural(tipoDocumento, numeroDocumento, numeroCaso ){
    return this.http.get(`${API_URI}api/PQR/ConsultaNatural?tipodocumento=${tipoDocumento}&documento=${numeroDocumento}&caso=${numeroCaso}`);
  }

  postConsultaPQRPunto(numeroPunto, numeroCaso){
    return this.http.get(`${API_URI}api/PQR/ConsultaPunto?caso=${numeroCaso}&punto=${numeroPunto}`);
  }

  postCrearPQRPunto(form){
    return this.http.post(`${API_URI}api/PQR/CrearPunto`,form);
  }

  postCrearPQRNatural(form){
    return this.http.post(`${API_URI}api/PQR/crearNatural`,form);
  }

  getTipoSolicitud(){
    return this.http.get(`${API_URI}api/PQR/tipoSolicitud`);
  }

  getTipoDocumento(){
    return this.http.get(`${API_URI}api/PQR/tipoDocumento`);
  }

  getMunicipios(valor){
    return this.http.get(`${API_URI}api/PQR/municipios?valor=${valor}`);
  }

  getMotivo(){
    return this.http.get(`${API_URI}api/PQR/MotivoSolicitud`);
  }
  getPunto(nombre){
    return this.http.get(`${API_URI}api/PQR/PuntosAtention?punto=${nombre}`);
  }

  getEspecialista(tipoDocumento, numeroDocumeno){
    return this.http.get(`${API_URI}api/PQR/Especialista?tipo=${tipoDocumento}&identificacion=${numeroDocumeno}`);
  }

  getContacto(tipoDocumento, numeroDocumeno){
    return this.http.get(`${API_URI}api/PQR/Contacto?tipo=${tipoDocumento}&identificacion=${numeroDocumeno}`);
  }

  getBusqueda(terminos){
    return this.http.get(`${API_URI}api/PageContent/Search?terms=${terminos}`);
  }

  getApiUri() {
    return API_URI;
  }
}
