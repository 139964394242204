import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;

  timeLine: any = {
    Nombre: 'Linea de tiempo',
    Ubicacion: '0',
    Contenido: [
      {
        title: 'Con efecty en tu red de negocios',
        subtitle:'Obtienes grandes beneficios',
        Fields: [
          {
            title: 'Ser parte',
            subtitle: 'de la red más grande de giros en Colombia',
            imageUrl: '/uploads/images/Grupo 1761.png',
            imageAlt: '/uploads/images/Grupo 1761.png',
            imageTitle: '/uploads/images/Grupo 1761.png',
          },
          {
            title: 'Rentabilizar',
            subtitle: 'tus puntos de atención',
            imageUrl: '/uploads/images/Grupo 1794.png',
            imageAlt: '/uploads/images/Grupo 1794.png',
            imageTitle: '/uploads/images/Grupo 1794.png',
          },
          {
            title: 'Aumentar',
            subtitle: 'tus ingresos',
            imageUrl: '/uploads/images/Grupo 1808.png',
            imageAlt: '/uploads/images/Grupo 1808.png',
            imageTitle: '/uploads/images/Grupo 1808.png',
          },
          {
            title: 'Prestar servicios',
            subtitle: 'de valor agregado a tus clientes',
            imageUrl: '/uploads/images/Grupo -66.png',
            imageAlt: '/uploads/images/Grupo -66.png',
            imageTitle: '/uploads/images/Grupo -66.png',
          },
          {
            title: 'Aumentar tráfico',
            subtitle: 'de personas',
            imageUrl: '/uploads/images/Grupo 1871.png',
            imageAlt: '/uploads/images/Grupo 1871.png',
            imageTitle: '/uploads/images/Grupo 1871.png',
          },
          {
            title: 'Generar',
            subtitle: 'venta cruzada',
            imageUrl: '/uploads/images/Grupo 1857.png',
            imageAlt: '/uploads/images/Grupo 1857.png',
            imageTitle: '/uploads/images/Grupo 1857.png',
          },
          {
            title: 'Lograr',
            subtitle: 'mayor aprovechamiento del espacio y tus recursos',
            imageUrl: '/uploads/images/Grupo 1835.png',
            imageAlt: '/uploads/images/Grupo 1835.png',
            imageTitle: '/uploads/images/Grupo 1835.png',
          },
          {
            title: 'Optimizar',
            subtitle: 'tus flujos de caja',
            imageUrl: '/uploads/images/Grupo -64.png',
            imageAlt: '/uploads/images/Grupo -64.png',
            imageTitle: '/uploads/images/Grupo -64.png',
          }
        ]
      }
    ]
  }



  constructor() {
   }

  ngOnInit() {
    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.timeLine = this.contenido;
    }
  }

}
