import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-slider-cards',
  templateUrl: './slider-cards.component.html',
  styleUrls: ['./slider-cards.component.scss']
})
export class SliderCardsComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;


  data: any = [
    {
      imageUrlMovil: 'uploads/images/slider-3-title-movil.png',
      imageUrlDesktop: 'uploads/images/slider-3-title.png',
      imageAlt: 'Alt de imagen',
      imageTitle: 'Title de imagen',
      Fields: [
      {
        title: 'Sitio web',
        imageUrl: '/uploads/images/Grupo 2074.png',
        imageAlt: '/uploads/images/Grupo 2074.png',
        imageTitle: '/uploads/images/Grupo 2074.png',
        link: 'web',
        button: 'Inicia sesión o regístrate'
      },
      {
        title: 'Aplicación',
        imageUrl: '/uploads/images/Grupo 2062.png',
        imageAlt: '/uploads/images/Grupo 2062.png',
        imageTitle: '/uploads/images/Grupo 2062.png',
        link: 'app',
        button: 'Descarga el app'
      },
      {
        title: 'Facebook',
        imageUrl: '/uploads/images/Grupo 2074.png',
        imageAlt: '/uploads/images/Grupo 2074.png',
        imageTitle: '/uploads/images/Grupo 2074.png',
        link: 'face',
        button: 'Conectar mi cuenta'
      },
      {
        title: 'Facebook',
        imageUrl: '/uploads/images/Grupo 2074.png',
        imageAlt: '/uploads/images/Grupo 2074.png',
        imageTitle: '/uploads/images/Grupo 2074.png',
        link: 'face',
        button: 'Conectar mi cuenta'
      }
    ]
  }];

  slideConfig = {
    "slidesToShow": 4,
    'slidesToScroll': 1,
    'arrows': true,
    "autoplay": true,
    "responsive": [
      {
        'breakpoint': 1024,
        'settings': {
          "slidesToShow": 4
        }
      },
      {
        'breakpoint': 768,
        'settings': {
          "slidesToShow": 1,
          'centerMode': false
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.data = this.contenido.Contenido;
    }
  }
}
