import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { Page } from 'src/app/_models/page';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { ComponentBase } from 'src/app/_models/component-base';
import { Almacen } from 'src/app/_classes/almacen';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() page: Page;
  @Input() api_url: any;  
  footer =
    {
      Nombre: 'Footer',
      Ubicacion: '19',
      Contenido: [
        {
          imageUrl: 'uploads/footer-animado3.gif',
          imageAlt:null,
          imageTitle:null,
          copyRight: {
            left: '',
            Fields: '',
            right: ''
          },
          copyRightUrl:  'uploads/footer-animado3.gif',
          copyRightAlt:  'uploads/footer-animado3.gif',
          copyRightTitle:  'uploads/footer-animado3.gif',
          Fields: [
            {
              Propiedad: 'colType1',
              imageUrl: 'uploads/logo-payty-animado.gif',
              imageAlt: 'uploads/logo-payty-animado.gif',
              imageTitle: 'uploads/logo-payty-animado.gif',
              description: '<div><div>Línea de atención Bogotá</div><div><strong>(1) 651 01 01 - (1)7566027</strong></div><div class="padding3"></div><div>Línea celular</div><div><strong>305 7341 346</strong></div><div class="padding3"></div><div>Línea nacional gratuita</div><div><strong>18000 186 027</strong></div><div class="padding3"></div><div>Lunes a viernes</div><div><strong>7:00 am - 5:00 pm</strong></div></div>',
              Fields: [
                {
                  icon: "fa-facebook",
                  link: '/whatsapp'
                },
                {
                  icon: "fa-twitter",
                  link: '/facebook'
                },
                {
                  icon: "fa-instagram",
                  link: '/twiitter'
                },
                {
                  icon: "fa-youtube",
                  link: '/youtube'
                },
              ]
            },
            {
              Propiedad: 'colType2',
              title: 'Conoce Payty',
              Fields: [
                  {
                    item: "<a href='./sobre-la-compania'>Plataforma de pagos online</a>"
                  },
                  {
                    item: "<a href='./gestion'>Cómo funciona</a>"
                  },
                  {
                    item: "<a href='./gestion'>Beneficios</a>"
                  }
                ]
            },
            {
              Propiedad: 'colType2',
              title: 'Soluciones sin página web',
              Fields: [
                  {
                    item: "<a href='#'>Portal empresarial</a>"
                  },
                  {
                    item: "<a href='#'>Portal canal corporativo</a>"
                  },
                  {
                    item: "<a href='#'>Portal colaboradores</a>"
                  },
                  {
                    item: "<a href='/portales-internos'>Portales internos</a>"
                  }
                ]
            },
            {
              Propiedad: 'colType2',
              title: 'Soluciones con página web',
              Fields: [
                {
                  item: "<a href='/atencion-al-cliente'>Atención al cliente</a>"
                },
                {
                  item: "<a href='/telefono-de-contacto'>Teléfonos de contacto por regional</a>"
                },
                {
                  item: "<a href='/radicar-pqr'>Radicar PQR</a>"
                },
                {
                  item: "<a href='/consultar-pqr'>Consultar PQR</a>"
                },
                {
                  item: "<a href='/normatividad'>Normatividad</a>"
                }
              ]
            }
          ]
        }
      ]
    }

  constructor(
    private templateService: TemplateServiceService,
    private almacen : Almacen,
  ) {
  }
  ngOnInit() {
    this.loadTemplate();
  }

  loadTemplate(){  
    this.almacen.currentBase.subscribe(
        (data: any) => {
          if(data){
            this.footer.Contenido = data.Footer;
            console.log('Footer',this.footer.Contenido);
          }

        },
        error => {
        }
      )
  }
}
