import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: 'app-international-gyres',
  templateUrl: './international-gyres.component.html',
  styleUrls: ['./international-gyres.component.scss']
})
export class InternationalGyresComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  internationalGyres: any=
    {
      Nombre: 'Giros internacionales',
      Ubicacion: '13',
      Contenido: [
        {
          imageTitleUrl: 'uploads/images/giros-internacionales-titulo-movil.png',
          imageTitleAlt: 'uploads/images/giros-internacionales-titulo-movil.png',
          imageTitleTitle: 'uploads/images/giros-internacionales-titulo-movil.png',
          imageButtonUrl: 'uploads/images/giros y finanzaslogo.png',
          imageButtonAlt: 'uploads/images/giros y finanzaslogo.png',
          imageButtonTitle: 'uploads/images/giros y finanzaslogo.png',
          subtitle: 'Haz giros internacionales a mas de 200 países del mundo a través de nuestra red de aliados.',
          description: 'Es un servicio que permite enviar y recibir transacciones de giros internacionales Western Union en línea en los puntos de atención al público Efecty a través de la figura de corresponsalía bancaria de Giros y Finanzas compañía de financiamiento',
          imageLeftUrl: 'uploads/images/giros-internacionales.png',
          imageLeftAlt: 'uploads/images/giros-internacionales.png',
          imageLeftTitle: 'uploads/images/giros-internacionales.png',
        }
      ]
    }

  constructor() { }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.internationalGyres = this.contenido;
    }

  }

}
