export class ComponentBase <T> {
    Nombre:     string;
    Ubicacion:  string;
    idtemplate: String;
    Contenido:  Array<Object>;

    constructor(options: {
        Nombre?:    string;
        Ubicacion?: string;
        idtemplate?: string;
        Contenido?: []
        } = {})
    {
        this.Nombre =    options.Nombre || '';
        this.Ubicacion = options.Ubicacion || '';
        this.idtemplate = options.idtemplate || '';
        this.Contenido = ComponentBase.setPropiedades(options.Contenido) || [];
    }

    public static getPropiedades(instance, idpage = null){
        let result = [];
        instance.forEach((element, indice) => {
            var keys = Object.getOwnPropertyNames(element);
            const aKeys = keys.map(clave => {
                return {
                    Propiedad: clave,
                    Contenido: element[clave],
                    Idpage: idpage,
                    orden: indice,
                };
            });
            result = result.concat(aKeys);
        });
        return result;
    }

    public static setPropiedades(data){
        if(!data){
            return data;
        }
        let result = [];
        data.forEach((element, indice) => {
            if(result[element.orden] == undefined){
                result[element.orden] = {};
            }
            result[element.orden][element.Propiedad] = element.Propiedad == "Fields" || element.Propiedad == 'copyRight' ?
                JSON.parse(element.Contenido) : element.Contenido;
        });
        return result;
    }

    public static setPropiedadesNombre(data){
        if(!data){
            return data;
        }
        let result = [];
        data.forEach((element, indice) => {
            result[element.Propiedad] = element.Contenido;
        });
        return result;
    }

}