import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentService } from './../../_services/templates.service';
import { TemplateBase } from './../../_models/template-base';
import { ActivatedRoute } from "@angular/router";
import { ComponentBase } from 'src/app/_models/component-base';

@Component({
  selector: "app-description-list",
  templateUrl: "./description-list.component.html",
  styleUrls: ["./description-list.component.scss"],
  providers: [ComponentService]
})
export class DescriptionListComponent implements OnInit {
  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
   data: any = {};

   banner = 0;

  /**
   * Template
   */
  public componentData: TemplateBase<any>;

  constructor(
    private service: ComponentService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {

    if (this.contenido !== undefined && this.contenido.Contenido.length !== 0) {
      this.data = this.contenido;
    }

    const slug = this.route.snapshot.data.breadcrumb;
  }
}
