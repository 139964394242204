import { FieldsBase } from './fields-base';

export class FieldsText extends FieldsBase<string> {
  controlType = 'text';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || 'text';
  }
}
