import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-we-are-efecty',
  templateUrl: './about-us-we-are-efecty.component.html',
  styleUrls: ['./about-us-we-are-efecty.component.scss']
})
export class AboutUsWeAreEfectyComponent implements OnInit {

  API_URI = API_URI;
  constructor() { }

  ngOnInit() {
  }

}
