import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldsBase } from '../class/fields-base';

@Injectable()
export class FieldsControlService {
  constructor() { }

  toFormGroup(Fields: FieldsBase<any>[] ) {
    let group: any = {};

    Fields.forEach(Field => {
      group[Field.key] = Field.required ?
        new FormControl(Field.value || '', [Validators.required]) :
        (
          Field.readOnly ?
            new FormControl({ disabled: true, value: Field.value || ''} || '') :
            new FormControl(Field.value || '', Field.validators.length ? Field.validators: null)
        );
    });

    return new FormGroup(group);
  }
}
